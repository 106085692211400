export interface AuthState {
  loginInfo: object;
  token: string;
  isLoading: Boolean;
  hasError: Boolean;
  resettingPassword: boolean;
  resetPasswordSuccess: boolean | null;
  resetPasswordError: null | Error;
  errorMessage?: string;
}

import {
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
} from '../../actionTypes';
import {
  REQUEST_USER_LOGIN,
  LOGIN_ERROR,
  USER_LOGGED_IN,
  LOGOUT_USER,
} from '../../actionTypes/Auth/Auth';

const initialState: AuthState = {
  loginInfo: {},
  token: '',
  resettingPassword: false,
  resetPasswordSuccess: null,
  resetPasswordError: null,
  isLoading: false,
  hasError: false,
};

export const authReducer = (
  state: AuthState = initialState,
  action: { type: string; payload: any },
) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case REQUEST_USER_LOGIN:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      };
    case USER_LOGGED_IN:
      const { success } = action.payload;
      return {
        ...state,
        loginInfo: action.payload,
        token: success,
        isLoading: false,
        hasError: false,
      };

    case LOGOUT_USER:
      return initialState;
    case RESET_PASSWORD_LOADING:
      return {
        ...state,
        resettingPassword: true,
        resetPasswordSuccess: null,
        resetPasswordError: null,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resettingPassword: false,
        resetPasswordSuccess: true,
        resetPasswordError: null,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resettingPassword: false,
        resetPasswordSuccess: false,
        resetPasswordError: action.payload?.error,
      };
    default:
      return state;
  }
};

import React from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import MailBoxImg from './mailbox.svg';
export const EmptyNotificationsView: React.FC = () => {
  return <Box sx={{
    maxWidth: '240px',
    height: '100%'
  }} rowGap={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center" data-sentry-element="Box" data-sentry-component="EmptyNotificationsView" data-sentry-source-file="EmptyNotificationsView.tsx">
      <MailBoxImg data-sentry-element="MailBoxImg" data-sentry-source-file="EmptyNotificationsView.tsx" />
      <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="EmptyNotificationsView.tsx">No notifications yet</Typography>
    </Box>;
};
import React, { useState } from 'react';
import { ErrorBoundaryFallbackProps } from './types';
import { Button, Stack, Typography } from '@mui/material';
import { ErrorBoundaryIcon } from '#/components/design-system/icons/ErrorBoundaryIcon';
import { HelpFormModal } from '#/components/Dashboard/SalesForceHelpModal';
export const ErrorBoundaryFallback: React.FC<ErrorBoundaryFallbackProps> = props => {
  const {
    retryCallback,
    className,
    sx = [],
    errorLabel = ''
  } = props;
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  return <Stack id="error-boundary-fallback" data-testid="error-boundary-fallback-wrapper" className={className} spacing={2} useFlexGap sx={[{
    height: 'calc(100% - 64px)',
    justifyContent: 'center',
    alignItems: 'center'
  }, ...(Array.isArray(sx) ? sx : [sx])]} data-sentry-element="Stack" data-sentry-component="ErrorBoundaryFallback" data-sentry-source-file="ErrorBoundaryFallback.tsx">
      <ErrorBoundaryIcon data-sentry-element="ErrorBoundaryIcon" data-sentry-source-file="ErrorBoundaryFallback.tsx" />
      <Typography fontSize={34} textAlign="center" data-sentry-element="Typography" data-sentry-source-file="ErrorBoundaryFallback.tsx">
        {errorLabel || 'Something went wrong'}
      </Typography>
      <Typography fontSize={16} textAlign="center" data-sentry-element="Typography" data-sentry-source-file="ErrorBoundaryFallback.tsx">
        Refresh the browser. If that does not work, log a support ticket with
        details of the page that is having an error.
      </Typography>
      <Stack direction="row" spacing={2} data-sentry-element="Stack" data-sentry-source-file="ErrorBoundaryFallback.tsx">
        <Button variant="contained" disableElevation onClick={() => retryCallback?.()} data-sentry-element="Button" data-sentry-source-file="ErrorBoundaryFallback.tsx">
          Retry
        </Button>
        <Button variant="outlined" onClick={() => {
        setIsHelpModalOpen(true);
      }} data-sentry-element="Button" data-sentry-source-file="ErrorBoundaryFallback.tsx">
          Open a support ticket
        </Button>
      </Stack>
      <HelpFormModal showTrigger={false} isHelpModalOpen={isHelpModalOpen} setIsHelpModalOpen={setIsHelpModalOpen} data-sentry-element="HelpFormModal" data-sentry-source-file="ErrorBoundaryFallback.tsx" />
    </Stack>;
};
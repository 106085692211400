import {
  FetchMonitoringWorkflowsAPIResponse,
  MonitoringWorkflowListItem,
} from '#/api/monitoring';
import {
  MonitoringWorkflow,
  MonitoringWorkflowListState,
} from '#/redux/reducers/monitoringWorkflowsReducer';
import { apiResponseDateToDate } from '#/Utils/date';

export const transformMonitoringWorkflow = (
  item: MonitoringWorkflowListItem,
): MonitoringWorkflow => {
  return {
    createdBy: item.createdBy,
    updatedBy: item.updatedBy,
    createdAt: apiResponseDateToDate(item?.createdAt),
    providerFileType: item.providerFileType,
    providerLastName: item.providerLastName,
    assignedStates: item.provider?.assignedStates?.sort() || [],
    providerStates: (
      item.provider?.assignedStates?.sort() ||
      item.providerStates?.sort() ||
      []
    ).join(', '),
    providerFirstName: item.providerFirstName,
    isCompleted: item.isCompleted,
    version: item.version,
    organizationId: item.organizationId,
    missingData: item.missingData,
    providerType: item.providerType,
    providerStatus: item.providerStatus,
    updatedAt: apiResponseDateToDate(item?.updatedAt),
    name: item.name,
    providerNpi: item.providerNpi,
    providerId: item.providerId,
    id: item.id,
    flags: item.flags,
    isFlagged: item.isFlagged,
    newFlagsSources: item.newFlagsSources,
    currentFlags: item.currentFlags,
    flagsObjects: item.flagsObjects,
    onStep: item.onStep,
    reviewCompleteDate: apiResponseDateToDate(
      item.monitoringWorkflowTimeline?.completedReviewDate,
    ),
    reviewCompletedBy: item.monitoringWorkflowTimeline?.completedReviewBy?.name,
    outreachReasons: item.outreachReasons,
    notes: !item.notes
      ? []
      : item.notes.map((note) => {
          return {
            ...note,
            createdAt: apiResponseDateToDate(note?.createdAt),
          };
        }),
    assignment: item?.assignment,
  };
};

export const transformMonitoringWorkflows = (
  response: FetchMonitoringWorkflowsAPIResponse,
): MonitoringWorkflowListState => {
  return {
    data: response.data.map(transformMonitoringWorkflow),
    count: response.count,
  };
};

import { MembershipOrganization } from '../../types/memberships.types';
import {
  BLOCK_USER_LOADING,
  BLOCK_USER_SUCCESS,
  BLOCK_USER_FAILURE,
  UNBLOCK_USER_LOADING,
  UNBLOCK_USER_SUCCESS,
  UNBLOCK_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ROLES_LOADING,
  UPDATE_USER_ROLES_SUCCESS,
  UPDATE_USER_ROLES_FAILURE,
} from '../actionTypes';

export interface OrganizationMembershipUser {
  id: string;
  status: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: string[];
  blocked?: boolean;
}

export interface OrganizationMembership {
  user: OrganizationMembershipUser;
  organizationId: string;
  membershipId: string;
  organizations: MembershipOrganization[];
}

export interface OrganizationMembershipState {
  data: OrganizationMembership[];
  loading?: boolean;
  blockingUserId?: string;
  unblockingUserId?: string;
  errorMessage?: string;
  count?: number;
  updatingRolesUserId?: string;
  updatingMembershipId?: string;
}

const initialState: OrganizationMembershipState = {
  data: [],
  count: 0,
  loading: false,
  errorMessage: undefined,
};

export const organizationMemberReducer = (
  state: OrganizationMembershipState = initialState,
  action: { type: string; payload: any },
) => {
  switch (action.type) {
    case BLOCK_USER_LOADING:
      return {
        ...state,
        blockingUserId: action.payload.userId,
      };
    case BLOCK_USER_SUCCESS:
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.user.id === action.payload.userId) {
            item.user.blocked = true;
          }
          return item;
        }),
        blockingUserId: undefined,
      };
    case BLOCK_USER_FAILURE:
      return {
        ...state,
        blockingUserId: undefined,
      };
    case UNBLOCK_USER_LOADING:
      return {
        ...state,
        unblockingUserId: action.payload.userId,
      };
    case UNBLOCK_USER_SUCCESS:
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.user.id === action.payload.userId) {
            item.user.blocked = false;
          }
          return item;
        }),
        unblockingUserId: undefined,
      };
    case UNBLOCK_USER_FAILURE:
      return {
        ...state,
        unblockingUserId: undefined,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        data: state.data.map((item) =>
          item.user.id === action.payload.userId
            ? {
                ...item,
                user: {
                  ...item.user,
                  firstName: action.payload.firstName,
                  lastName: action.payload.lastName,
                },
              }
            : item,
        ),
      };
    case UPDATE_USER_ROLES_LOADING:
      return {
        ...state,
        updatingRolesUserId: action.payload,
      };
    case UPDATE_USER_ROLES_SUCCESS:
      return {
        ...state,
        updatingRolesUserId: undefined,
        data: state.data.map((item) =>
          item.user.id === action.payload.userId
            ? {
                ...item,
                user: {
                  ...item.user,
                  roles: action.payload.newRoles,
                },
              }
            : item,
        ),
      };
    case UPDATE_USER_ROLES_FAILURE:
      return {
        ...state,
        updatingRolesUserId: undefined,
      };
    default:
      return state;
  }
};

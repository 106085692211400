import { ApiResponseDateTime } from '#/Utils/types';
import { PaginationQueryParams } from '#/services';
import { HsdSpecialty, Specialties } from './specialties.types';
import { ContractSignatory } from './provider-enrollment-workflows.types';

export interface CreateOrgAddressPayload {
  addressLine1: string;
  zip: string;
  city: string;
  state: string;
  country: string;
  county?: string;
  sameAsPrimary?: boolean;
}

export interface ValidateOrgEntityPayload {
  name: string;
  npi: string;
  tin: string;
}

export interface orgEntityGeneralInfo {
  dba?: string;
  deaNumber?: string;
  practiceType?: string;
  website?: string;
  siteLoginUsername?: string;
  networkEffectiveDate?: ApiResponseDateTime;
  name: string;
  specialty?: string;
  npi: string;
  tin: string;
  affiliation: string;
  externalGroupId?: string;
  isSoloPractice?: string;
  groupType?: string;
  sitePassword?: string;
}

export interface CreateOrgEntityPayload {
  generalInfo: orgEntityGeneralInfo;
  contactPerson?: {
    fullName?: string;
    phone?: string;
    email?: string;
    fax?: string;
    contractSignatory?: ContractSignatory;
  };
  externalGroupId?: string;
  serviceAddress?: CreateOrgAddressPayload;
  mailingAddress?: CreateOrgAddressPayload;
  billingAddress?: CreateOrgAddressPayload;
  ownTags?: string[];
  npdbEnrollmentEnabled?: boolean;
  changeReason?: string;
}

export interface UpdateOrgEntityPayload {
  npdbEnrollmentEnabled?: boolean;
}

export interface EntityListFilterPayload {
  npi?: string;
  name?: string;
  specialty?: string;
  affiliation?: string;
}

export interface CreateOrgEntityTaxInfoPayload {
  legalTaxName: string;
  taxEntityStates: string[];
  irs575File?: File;
  w9File?: File;
  corporateStructure?: string;
  certificationNumber?: string;
  corporateEffectiveDate?: string;
  legalActions?: string;
  supplierType?: string;
  billingAddress?: CreateOrgAddressPayload;
  w9Address?: CreateOrgAddressPayload;
  MRStorageAddress?: CreateOrgAddressPayload;
}

export interface UpdateEntityDocumentsPayload {
  certificateOfInsurance?: File;
  disclosureOfOwnershipForm?: File;
  eftForm?: File;
  letterOfAppeal?: File;
  supplierAgreement460?: File;
  certificateOfGoodStanding?: File;
  fictitousNamePermit?: File;
  bankLetter?: File;
  organizationChart?: File;
  workflowId?: string;
}

export interface EntityDocument {
  id: string;
  type: string;
  originalName: string;
  path: string;
  createdBy: string;
  createdByName: string;
  createdAt: ApiResponseDateTime;
  createdAtDate: Date;
  timeAgo: string;
}

export type OrgEntityTaxInfo = {
  legalTaxName: string;
  taxEntityStates: string[];
  irs575File?: string;
  w9File?: string;
  corporateStructure?: string;
  certificationNumber?: string;
  corporateEffectiveDate?: string;
  legalActions?: string;
  supplierType?: string;
  billingAddress: CreateOrgAddressPayload;
  w9Address: CreateOrgAddressPayload;
  MRStorageAddress: CreateOrgAddressPayload;
};

export type SingleOrgEntityResponse = {
  id: string;
  generalInfo: {
    dba?: string;
    deaNumber?: string;
    practiceType?: string;
    website?: string;
    siteLoginUsername?: string;
    networkEffectiveDate?: ApiResponseDateTime;
    name: string;
    specialty?: string;
    npi: string;
    tin: string;
    affiliation: string;
    isSoloPractice?: string;
    groupType?: string;
    sitePassword?: string;
  };
  contactPerson: {
    fullName: string;
    phone: string;
    email: string;
    fax: string;
    contractSignatory?: ContractSignatory;
  };
  externalGroupId?: string;
  taxInfo: OrgEntityTaxInfo;
  createdAt: ApiResponseDateTime;
  createdBy: string;
  createdByName: string;
  updatedAt: ApiResponseDateTime;
  updatedBy: string;
  updatedByName: string;
  serviceAddress?: CreateOrgAddressPayload;
  mailingAddress?: CreateOrgAddressPayload;
  billingAddress?: CreateOrgAddressPayload;
  w9Address?: CreateOrgAddressPayload;
  MRStorageAddress?: CreateOrgAddressPayload;
  active: boolean;
  providers?: string[];
  locations?: string[];
  enrollments?: string[];
  tags?: string[];
  locationCount: number;
  providerCount: number;
  effectiveProviderEnrollmentCount: number;
  unenrolledProviderCount: number;
  inProgressProviderEnrollmentCount: number;
};

export type FetchEntitiesResponse = {
  data: SingleOrgEntityResponse[];
  total: number;
};

export interface FetchEntitiesQuery extends PaginationQueryParams {
  search?: string;
  providerId?: string;
  groupId?: string;
  npdbEnrollmentEnabled?: boolean;
}

export type FetchEntityDocumentsResponse = {
  data: EntityDocument[];
  total: number;
};

export interface OrgEntityDetails extends SingleOrgEntityResponse {
  dynamicFields?: any;
  overview: {
    name: string;
    dba: string;
    affiliation: string;
    tin: string;
    npi: string;
    externalGroupId?: string;
    specialty: string;
    createdAt?: Date;
    networkEffectiveDate?: Date;
    serviceAddress?: string;
    mailingAddress?: string;
    billingAddress?: string;
    phone?: string;
    fax?: string;
    states?: string[];
    isSoloPractice?: string;
    practiceType?: string;
    groupType?: string;
    website?: string;
    siteLoginUsername?: string;
    deaNumber?: string;
    sitePassword?: string;
    locationCount?: number;
    providerCount?: number;
    effectiveProviderEnrollmentCount?: number;
    unenrolledProviderCount?: number;
    inProgressProviderEnrollmentCount?: number;
    tags?: string;
  };
  npdbEnrollmentEnabled?: boolean;
  specialties?: Specialties[];
  hsdSpecialty?: HsdSpecialty | null;
  primarySpecialty?: Specialties | null;
}

export interface EnrollmentGeneralInfo {
  jurisdiction: string;
  lineOfBusiness: string;
  planId: string;
  payorId: string;
  planName?: string;
  payorName?: string;
}

export interface EnrollmentAddress {
  addressType: string;
  addressLine1: string;
  zip: string;
  city: string;
  state: string;
  county: string;
  country: string;
}

export const enrollmentKeyContactTypes = [
  '>5% ownership',
  'Office Contact',
  'Credentialing Contact',
  'Contracting Contact',
  'Billing Manager',
];

export interface EnrollmentKeyContact {
  type: string;
  fullName: string;
  email: string;
  phone: string;
  fax: string;
}

export interface ContractDetails {
  createdAt: ApiResponseDateTime;
  fileName: string;
  contractUrl: string;
  createdBy: string;
  createdByName?: string;
  docUploadedByUser: boolean;
}

export interface Enrollment {
  groupId?: string;
  groupName?: string;
  id: string;
  generalInfo: EnrollmentGeneralInfo;
  addresses: EnrollmentAddress[];
  keyContacts?: EnrollmentKeyContact[];
  status?: string;
  generatedId: string;
  providers?: string[];
  providerCount?: number;
  enrollmentEffectiveDate?: Date;
  terminationDate?: Date;
  terminationInfoDate?: Date;
  contractDetails: ContractDetails[];
  organizationId?: string;
  createdAt?: Date;
  createdByName?: string;
  createdBy?: string;
}

export interface FetchOrgEntityEnrollmentsResponse {
  data: Enrollment[];
  total: number;
}

interface EntityEnrollmentRowItem {
  groupId?: string;
  id: string;
  groupName?: string;
  payorName?: string;
  planName?: string;
  payorId?: string;
  planId?: string;
  jurisdiction: string;
  lineOfBusiness: string;
  status?: string;
  pendingTasks?: string[];
  generatedId: string;
  providerCount?: number;
}

export interface EntityEnrollmentDetails {
  data: EntityEnrollmentRowItem[];
  total: number;
}

export interface FetchEntityEnrollmentByIdResponse {
  dynamicFields?: Record<string, any> | undefined;
  id: string;
  enrollmentCounty: string;
  enrollmentTermDate: ApiResponseDateTime;
  enrollmentEffectiveDate: ApiResponseDateTime;
  groupId?: string;
  groupName?: string;
  generalInfo: EnrollmentGeneralInfo;
  addresses?: EnrollmentAddress[];
  keyContacts?: EnrollmentKeyContact[];
  generatedId: string;
  status?: string;
  organizationId: string;
  createdAt: ApiResponseDateTime;
  createdBy: string;
  createdByName: string;
  updatedAt?: ApiResponseDateTime;
  updatedBy?: string;
  updatedByName?: string;
  orgEntityContractStatus?: string;
  contractType?: string;
  contractAgreementType?: string;
  billingEffectiveDate?: ApiResponseDateTime;
  billingTermDate?: ApiResponseDateTime;
  contractDetails?: ContractDetails[];

  contractPaymentType?: string;
  nonTypicalContractTerms?: string;
  providerRateCalculation?: string;
  lesserOf?: string;
  sequestrationAllowed?: string;
  additionalFeeSchedule?: string;
  claimSubmissionCycleTime?: string;
  sharedSavings?: string;
  bonus?: string;

  snfCapitationBillAboveRate?: string;
  snfCapitationPartARate?: string;
  snfCapitationPartBRate?: string;
  snfCorporateChampionRate?: string;

  hasGssRate?: string;
  gssMinimumMm?: string;
  gssRateDetails?: string;
  gssTerms?: string;

  appPenalty?: string;
  telehealthNoShow?: string;

  hasQualityProgram?: string;
  qualityProgramInitiatives?: string;
  averageStarsAndPmpmBonus?: string;
  hasMlrTarget?: string;
  mlrTargetDescription?: string;
  credentialingWorkflowTimeline?: {
    lastCredentialingDate?: ApiResponseDateTime;
    nextCredentialingDate?: ApiResponseDateTime;
  };
}

export interface FetchEntityEnrollmentProvidersResponse {
  data: EntityProviderResponse[];
  total: number;
}

export interface CreateEnrollmentPayload {
  generalInfo: EnrollmentGeneralInfo;
  addresses?: EnrollmentAddress[];
  keyContacts?: EnrollmentKeyContact[];
  status?: string;
  nextCredentialingDate?: ApiResponseDateTime;
  lastCredentialingDate?: ApiResponseDateTime;
  dynamicData?: Record<string, any>;
  enrollmentCounty?: string;
  enrollmentTermDate?: Date;
  enrollmentEffectiveDate?: Date;
  groupId?: string;
  groupName?: string;
}

export type UpdateEnrollmentPayload = Partial<CreateEnrollmentPayload> & {
  status?: string;
  statusReason?: string;
  reason?: string;
  statusNotes?: string;
  terminationDate?: Date;
  terminationInfoDate?: Date;
  includeAllProviders?: boolean;
  providerEnrollmentIds?: string[];
  requestType: string;
  editProviderId?: string;
};

export const UPDATE_ENROLLMENT_REQUEST_TYPES = {
  GROUP: 'Group',
  INDIVIDUAL: 'Individual',
};

export interface EnrollmentAddressFormFieldParams {
  label: string;
  name: keyof EnrollmentAddress;
  colSpacing: number;
  type?: string;
  options?: string[];
  disabled?: boolean;
}

export type EntityLocationAdaCompliance = {
  accessibilityToTheBuildingEntrances?: boolean;
  accessibilityToTheBuildingSpaces?: boolean;
  receptionAndWaitingAreas?: boolean;
  examRoomsAndTreatmentAreas?: boolean;
  restrooms?: boolean;
  signageAndWayfinding?: boolean;
  communicationAccomodations?: boolean;
  policiesAndProcedures?: boolean;
};

export type EntityLocationResponse = {
  id: string;
  active?: boolean;
  updatedBy: string;
  updatedByName: string;
  generalInfo: {
    locationName: string;
    mainPhoneNumber: string;
    suppressSiteFromDirectory: boolean;
    type: string;
    afterHoursPhoneNumber?: string;
    fax?: string;
  };
  locationSpecialties?: Specialties[] | null;
  locationPrimarySpecialty?: Specialties | null;
  locationHsdSpecialty?: HsdSpecialty | null;
  officeHours?: {
    daysOfWeek: string[];
    hourFrom: string;
    hourTo: string;
  }[];
  hospitalAdmittingPrivileges?: {
    privilege: string;
    effectiveDate: Date;
  }[];
  limitationsForMembers?: string[];
  adaCompliance?: EntityLocationAdaCompliance;
  serviceAddress: CreateOrgAddressPayload;
  mailingAddress?: CreateOrgAddressPayload;
  billingAddress?: CreateOrgAddressPayload;
  address?: string;
  providers?: string[];
  tags?: string[];
};

export type FetchLocationsResponse = {
  data: EntityLocationResponse[];
  total: number;
};

export type EntityLocationOfficeHour = {
  daysOfWeek: string[];
  hourFrom: string;
  hourTo: string;
};

export interface CreateEntityLocationPayload {
  active?: boolean;
  generalInfo: {
    locationName: string;
    mainPhoneNumber: string;
    suppressSiteFromDirectory: boolean;
    type: string;
    afterHoursPhoneNumber?: string;
    fax?: string;
  };
  locationSpecialties?: Specialties[] | null;
  locationPrimarySpecialty?: Specialties | null;
  locationHsdSpecialty?: HsdSpecialty | null;
  officeHours?: EntityLocationOfficeHour[];
  hospitalAdmittingPrivileges?: {
    privilege: string;
    effectiveDate: Date;
  }[];
  limitationsForMembers?: string[];
  adaCompliance: EntityLocationAdaCompliance;
  serviceAddress: CreateOrgAddressPayload;
  mailingAddress?: CreateOrgAddressPayload;
  billingAddress?: CreateOrgAddressPayload;
  tags?: string[];
}

export interface EntityLocationFilterPayload {
  locationName?: string;
  type?: string;
  city?: string;
  state?: string;
  zip?: string;
  adaCompliance?: string;
}

export interface EntityProviderFilterPayload {
  npi?: string;
  name?: string;
  providerType?: string;
  specialty?: string;
}

export interface EntityEnrollmentFilterPayload {
  payorId?: string;
  planId?: string;
  jurisdiction?: string;
  status?: string;
  lineOfBusiness?: string;
  isContractUploaded?: string;
}

export type EntityProviderResponse = {
  id: string;
  providerId: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  providerType: string;
  externalId?: string;
  npi?: string;
  caqhProviderId?: string;
  states?: string[];
  specialty?: string;
  orgEntityLocations?: string[];
  orgEntityEnrollments?: string[];
  orgEntities?: string[];
  enrollmentStatus?: string;
  canDissociate: boolean;
};

export type FetchEntityProvidersResponse = {
  data: EntityProviderResponse[];
  count: number;
};

export type CreateEntityProviderPayload = {
  firstName: string;
  middleName?: string;
  lastName: string;
  providerType: string;
  externalId?: string;
  npi?: string;
  caqhProviderId?: string;
  states?: string[];
  specialty?: string;
  orgEntityLocations?: string[];
  orgEntityEnrollments?: string[];
};

export type CreateOrgEntityProviderEnrollmentPayload = {
  list: {
    editProviderId: string;
    enrollmentId: string;
  }[];
};

export interface Payor {
  id: string;
  name: string;
  searchName: string;
  active: boolean;
  createdBy: string;
  createdByName: string;
  createdAt: ApiResponseDateTime;
}

export interface FetchPayorListResponse {
  data: Payor[];
  total: number;
}

export interface Plan {
  id: string;
  name: string;
  searchName: string;
  active: boolean;
  createdBy: string;
  createdByName: string;
  createdAt: ApiResponseDateTime;
}

export interface FetchPlanListResponse {
  data: Plan[];
  total: number;
}

export type FetchAllPlansListResponse = Pick<Plan, 'id' | 'name'>[];
export type FetchAllPayorsListResponse = Pick<Payor, 'id' | 'name'>[];

export interface BulkUploadResponse {
  creationOveralStatus: string;
  validationErrors: unknown[];
}

export interface ResubmitPEWorkflowRequest {
  workflowId: string;
  providerWorkflowIds?: string[];
  note: string;
  reason: string;
}

export interface BulkResubmitPEWorkflowsRequest {
  workflowIds: string[];
  note: string;
  reason: string;
}

export interface EnrollmentPlanSelectionOption {
  value: string;
  label: string;
}

export interface OrgEntity {
  id: string;
  generalInfo: {
    name: string;
  };
}

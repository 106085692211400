import { applicationApi } from './application-api.service';
import {
  UpdateUserByIdPayload,
  FetchUserApiResponse,
  FetchUsersApiResponse,
  FetchUsersApiPayload,
  UserDetails,
  CreateUserRequestDto,
} from '#/types';
import { TAGS_MAP } from './tags/tags';

export const usersApiV2 = applicationApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchUsers: builder.query<FetchUsersApiResponse, FetchUsersApiPayload>({
      query: (params) => ({
        url: `/users`,
        method: 'get',
        params,
      }),
    }),
    fetchUserById: builder.query<FetchUserApiResponse, string>({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'get',
      }),
      providesTags: [TAGS_MAP.LOGGED_IN_USER],
    }),
    createUser: builder.mutation<UserDetails, CreateUserRequestDto>({
      query: (createUserPayload) => ({
        url: `/users`,
        method: 'post',
        data: createUserPayload,
      }),
    }),

    updateUserById: builder.mutation<
      unknown,
      { id: string; payload: UpdateUserByIdPayload }
    >({
      query: ({ id, payload }) => ({
        url: `/users/${id}`,
        method: 'patch',
        data: payload,
      }),
      invalidatesTags: [TAGS_MAP.LOGGED_IN_USER],
    }),
    blockUser: builder.mutation<void, string>({
      query: (id) => ({
        url: `/users/${id}/block`,
        method: 'patch',
      }),
    }),
    unblockUser: builder.mutation<void, string>({
      query: (id) => ({
        url: `/users/${id}/unblock`,
        method: 'patch',
      }),
    }),
  }),
});

export const {
  useLazyFetchUserByIdQuery,
  useFetchUsersQuery,
  useFetchUserByIdQuery,
  useUpdateUserByIdMutation,
  useBlockUserMutation,
  useUnblockUserMutation,
  useCreateUserMutation,
} = usersApiV2;

import { SerializedError } from '@reduxjs/toolkit';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios from 'axios';
import type { AxiosRequestConfig, AxiosError } from 'axios';

interface IBaseQueryParams {
  baseUrl: string;
}

interface IBaseQueryArgs {
  url: string;
  method?: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  /* For code generated via @rtk-query/codegen-openapi */
  body?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  headers?: AxiosRequestConfig['headers'];
}

interface AxiosBaseQueryError {
  status?: number;
  data?: any;
}

export function isAxiosBaseQueryErrorError(
  error: AxiosBaseQueryError | SerializedError | undefined,
): error is AxiosBaseQueryError {
  return !!(error as AxiosBaseQueryError)?.status;
}

export function axiosBaseQuery(
  { baseUrl }: IBaseQueryParams = { baseUrl: '' },
): BaseQueryFn<IBaseQueryArgs, unknown, AxiosBaseQueryError> {
  return async ({
    url,
    method,
    data,
    body,
    params,
    ...rest
  }: IBaseQueryArgs) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method: method || 'GET',
        data: data ?? body,
        params,
        ...rest,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
}

import { Dispatch } from '@reduxjs/toolkit';
import * as authApi from '../../../api/auth';
import {
  REQUEST_USER_LOGIN,
  LOGIN_ERROR,
  USER_LOGGED_IN,
  LOGOUT_USER,
} from '../../actionTypes/Auth/Auth';
import {
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  UPDATE_SELECTED_ORG,
} from '../../actionTypes';
import { clientApi } from '../../../services';

export const requestUserLogin = () => {
  return {
    type: REQUEST_USER_LOGIN,
  };
};

export const userLoggedIn = (loginInfo: any) => {
  return {
    type: USER_LOGGED_IN,
    payload: loginInfo,
  };
};

export const logIn = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(requestUserLogin());
      const loginInfo = await authApi.login(data);

      dispatch(userLoggedIn(loginInfo));
    } catch (e) {
      dispatch(Logout);
    }
    dispatch(clientApi.util.invalidateTags(['TAG_AUTH', 'TAG_SESSION']));
  };
};

export const checkTokenAction = () => {
  return async (dispatch: any) => {
    try {
      dispatch(requestUserLogin());
      const loginInfo = await authApi.checkToken();
      dispatch(userLoggedIn(loginInfo));
    } catch (e) {
      logout()(dispatch);
    }
    dispatch(clientApi.util.invalidateTags(['TAG_AUTH', 'TAG_SESSION']));
  };
};

export const logout = () => {
  return async (dispatch: any) => {
    const result = await authApi.logout();
    if (result.success) {
      console.log('logout');
      dispatch(clientApi.util.invalidateTags(['TAG_AUTH', 'TAG_SESSION']));

      dispatch(Logout());
    }
  };
};
export const LoginError = (errorMessage?: string) => {
  return {
    type: LOGIN_ERROR,
    payload: {
      errorMessage,
    },
  };
};

export const Logout = () => {
  return {
    type: LOGOUT_USER,
  };
};

export const resetPassword = (currentPassword: string, newPassword: string) => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch({
      type: RESET_PASSWORD_LOADING,
    });
    try {
      await authApi.resetPassword('me', currentPassword, newPassword);
      return dispatch({
        type: RESET_PASSWORD_SUCCESS,
      });
    } catch (error: any) {
      return dispatch({
        type: RESET_PASSWORD_FAILURE,
        payload: { error },
      });
    }
  };
};

export const updateSelectedOrg = (orgId: string) => {
  return {
    type: UPDATE_SELECTED_ORG,
    payload: { orgId },
  };
};

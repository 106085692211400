import React from 'react';
import { ErrorBoundary } from '@sentry/nextjs';
import { ErrorBoundaryFallback } from '#/components/CustomErrorBoundary/ErrorBoundaryFallback';
import { CustomErrorBoundaryProps } from '#/components/CustomErrorBoundary/types';
export const CustomErrorBoundary = (props: CustomErrorBoundaryProps) => {
  const {
    children,
    retryCallback,
    errorLabel,
    className,
    showDialog,
    sx = []
  } = props;
  const fallbackElement = ({
    resetError
  }: {
    resetError: () => void;
  }) => {
    return <ErrorBoundaryFallback className={className} errorLabel={errorLabel} sx={sx} retryCallback={() => {
      retryCallback?.();
      resetError?.();
    }} data-sentry-element="ErrorBoundaryFallback" data-sentry-component="fallbackElement" data-sentry-source-file="CustomErrorBoundary.tsx" />;
  };
  return <ErrorBoundary fallback={fallbackElement} showDialog={showDialog} data-sentry-element="ErrorBoundary" data-sentry-component="CustomErrorBoundary" data-sentry-source-file="CustomErrorBoundary.tsx">
      {children}
    </ErrorBoundary>;
};
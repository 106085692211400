import { HIDE_ALL_HELP_BANNERS, HIDE_HELP_BANNER } from '../actionTypes';

export const hideHelpBanners = (bannerName: string) => {
  return {
    type: HIDE_HELP_BANNER,
    payload: {
      bannerName,
    },
  };
};

export const hideAllHelpBanners = () => {
  return {
    type: HIDE_ALL_HELP_BANNERS,
    payload: {},
  };
};

import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
export const LicensingIcon: React.FC<SvgIconProps> = props => {
  return <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="SvgIcon" data-sentry-component="LicensingIcon" data-sentry-source-file="licensing.tsx">
      <path opacity="0.3" d="M20 7H15V4C15 2.9 14.1 2 13 2H11C9.9 2 9 2.9 9 4V7H4C2.9 7 2 7.9 2 9V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V9C22 7.9 21.1 7 20 7Z" data-sentry-element="path" data-sentry-source-file="licensing.tsx" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8 4C8 2.34772 9.34772 1 11 1H13C14.6523 1 16 2.34772 16 4V6H20C21.6523 6 23 7.34772 23 9V20C23 21.6523 21.6523 23 20 23H4C2.34772 23 1 21.6523 1 20V9C1 7.34772 2.34772 6 4 6H8V4ZM11 3C10.4523 3 10 3.45228 10 4V8H4C3.45228 8 3 8.45228 3 9V20C3 20.5477 3.45228 21 4 21H20C20.5477 21 21 20.5477 21 20V9C21 8.45228 20.5477 8 20 8H14V4C14 3.45228 13.5477 3 13 3H11Z" data-sentry-element="path" data-sentry-source-file="licensing.tsx" />
      <path d="M10.5 13.5C10.5 12.67 9.83 12 9 12C8.17 12 7.5 12.67 7.5 13.5C7.5 14.33 8.17 15 9 15C9.83 15 10.5 14.33 10.5 13.5Z" data-sentry-element="path" data-sentry-source-file="licensing.tsx" />
      <path d="M6 18H12V17.25C12 16.25 10 15.75 9 15.75C8 15.75 6 16.25 6 17.25V18Z" data-sentry-element="path" data-sentry-source-file="licensing.tsx" />
      <path d="M11 9H13V4H11V9Z" data-sentry-element="path" data-sentry-source-file="licensing.tsx" />
      <path d="M14 16.5H18V15H14V16.5Z" data-sentry-element="path" data-sentry-source-file="licensing.tsx" />
      <path d="M14 13.5H18V12H14V13.5Z" data-sentry-element="path" data-sentry-source-file="licensing.tsx" />
    </SvgIcon>;
};
import React, { useMemo, useState } from 'react';
import { Avatar, Button, Input, List, ListItemButton, ListItemText, Stack, ClickAwayListener, styled } from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import SearchIcon from '@mui/icons-material/Search';
import { ANALYST_ROLE, SUPERVISOR_ROLE, allOrgs, allOrgsValue } from '#/Utils/Constants';
import { useFeatureFlags } from '#/hooks/useFeatureFlags';
import { useSelector } from 'react-redux';
import { AppState } from '#/redux/reducers/rootReducer';
export enum SelectorDirection {
  top = 'top',
  bottom = 'bottom',
}
export interface OrgSelectorItem {
  key: string;
  value: string;
  icon?: JSX.Element;
}
export interface SelectorProps {
  items: OrgSelectorItem[];
  placeholder: string;
  searchInputPlaceholder: string;
  direction?: SelectorDirection;
  onChange?: (value: OrgSelectorItem) => null;
  value?: OrgSelectorItem;
  defaultIcon?: JSX.Element | null;
  hideItemIcons?: boolean;
}
const ListContainer = styled(Stack)(() => ({
  background: '#eee',
  height: '160px',
  margin: '16px 0',
  borderRadius: '8px',
  width: '100%'
}));
const SearchInputContainer = styled(Stack)(() => ({
  margin: '8px 8px 0'
}));
const OptionsList = styled(List)(() => ({
  overflow: 'scroll'
}));
const FoldIcon = styled(UnfoldLessIcon)(() => ({
  marginLeft: 'auto'
}));
const UnfoldIcon = styled(UnfoldMoreIcon)(() => ({
  marginLeft: 'auto'
}));
const SelectorIcon = styled(Avatar)(({
  theme
}) => ({
  background: theme.palette.primary.dark,
  marginRight: '16px',
  padding: 0
}));
const SelectorButton = styled(Button)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start !important',
  textTransform: 'none',
  '.all-org &': {
    backgroundColor: '#757575',
    '&:hover': {
      backgroundColor: '#696969'
    }
  }
}));
const AllOrgListItemButton = styled(ListItemButton)(() => ({
  backgroundColor: '#757575',
  color: 'white',
  '&:hover': {
    backgroundColor: '#696969'
  }
}));
const SearchInput = styled(Input)(() => ({
  marginLeft: '8px',
  '& input': {
    paddingBottom: '0 !important'
  }
}));
export const Selector: React.FC<SelectorProps> = ({
  items,
  placeholder,
  searchInputPlaceholder,
  direction,
  onChange,
  value,
  defaultIcon,
  hideItemIcons
}) => {
  const [open, setOpen] = useState(false);
  const [filteredItems, setFilteredItems] = useState<OrgSelectorItem[]>(items);
  const {
    all_org_pages
  } = useFeatureFlags();
  const loggedInUser = useSelector((state: AppState) => state.loggedInUser);
  const isCertifyosSupervisorOrAnalyst = useMemo(() => loggedInUser.data?.email?.includes('@certifyos.com') && (!!loggedInUser.data?.roles?.includes(SUPERVISOR_ROLE) || !!loggedInUser.data?.roles?.includes(ANALYST_ROLE)), [loggedInUser]);
  const onButtonClick = () => {
    setFilteredItems(items);
    setOpen(!open);
  };
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const searchRegex = new RegExp(e.target.value, 'ig');
    setFilteredItems(items.filter(item => item.value.match(searchRegex) !== null));
  };
  const onSelect = (item: OrgSelectorItem) => {
    // setSelected(item);
    setOpen(false);
    setFilteredItems(items);
    onChange && onChange(item);
  };
  const onClickOutsideSelector = () => {
    setOpen(false);
    setFilteredItems(items);
  };
  const selectorList = <ClickAwayListener onClickAway={onClickOutsideSelector}>
      <ListContainer>
        <SearchInputContainer direction="row" alignItems="center">
          <SearchIcon fontSize="small" color="disabled" />
          <SearchInput placeholder={searchInputPlaceholder} disableUnderline onChange={onSearch} size="small" />
        </SearchInputContainer>
        <OptionsList dense data-testid={'orgList'}>
          {all_org_pages && isCertifyosSupervisorOrAnalyst && <AllOrgListItemButton key={'all-orgs'} onClick={() => onSelect(allOrgs)}>
              <ListItemText primaryTypographyProps={{
            fontWeight: 'bold'
          }} primary={allOrgs.value} />
            </AllOrgListItemButton>}
          {filteredItems.map(item => {
          return <ListItemButton key={item.key} onClick={() => onSelect(item)}>
                <ListItemText primary={item.value} />
              </ListItemButton>;
        })}
        </OptionsList>
      </ListContainer>
    </ClickAwayListener>;
  const avatar = value && !hideItemIcons ? value.icon || value.value?.length && value.value[0] : defaultIcon;
  return <div className={value?.key === allOrgsValue ? 'all-org' : ''} data-sentry-component="Selector" data-sentry-source-file="Selector.tsx">
      {open && direction === SelectorDirection.top && selectorList}
      <SelectorButton variant="contained" onClick={onButtonClick} data-sentry-element="SelectorButton" data-sentry-source-file="Selector.tsx">
        <SelectorIcon data-sentry-element="SelectorIcon" data-sentry-source-file="Selector.tsx">{avatar}</SelectorIcon>
        {value ? value.value : placeholder}
        {open ? <FoldIcon /> : <UnfoldIcon />}
      </SelectorButton>
      {open && direction === SelectorDirection.bottom && selectorList}
    </div>;
};
Selector.defaultProps = {
  direction: SelectorDirection.bottom,
  defaultIcon: null
};
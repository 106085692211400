import { HIDE_ALL_HELP_BANNERS, HIDE_HELP_BANNER } from '../actionTypes';

export interface HelpBannerState {
  visibility: {
    dashboard1: boolean;
    dashboard2: boolean;
    providers: boolean;
    credentialing: boolean;
    credComm: boolean;
  };
}

const initialState = {
  visibility: {
    dashboard1: true,
    dashboard2: true,
    providers: true,
    credentialing: true,
    credComm: true,
  },
};

export const helpBannersReducer = (
  state: HelpBannerState = initialState,
  action: { type: string; payload: any },
) => {
  switch (action.type) {
    case HIDE_HELP_BANNER:
      return {
        ...state,
        visibility: {
          ...state.visibility,
          [action.payload.bannerName]: false,
        },
      };
    case HIDE_ALL_HELP_BANNERS:
      return {
        ...state,
        visibility: {
          dashboard1: false,
          dashboard2: false,
          providers: false,
          credentialing: false,
          credComm: false,
        },
      };
    default:
      return state;
  }
};

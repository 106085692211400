import { TagDescription } from '@reduxjs/toolkit/dist/query';
import { FullTagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export const tagsWithId = function addTagsWithId<
  TagType,
  Data extends { id?: string | number },
>(tag: TagDescription<TagType>, result?: Data) {
  return result?.id
    ? [
        tag,
        { type: tag as TagType, id: result?.id } as FullTagDescription<TagType>,
      ]
    : [tag];
};

export const tagsWithIdList = function addTagsWithIdList<
  TagType,
  Data extends { id?: string | number },
>(tag: TagDescription<TagType>, result?: Data[]) {
  if (!result) {
    return [tag];
  }
  return [
    tag,
    ...result.flatMap((data) => {
      return data?.id
        ? [
            {
              type: tag as TagType,
              id: data?.id,
            } as FullTagDescription<TagType>,
          ]
        : [];
    }),
  ];
};

import { Appbar } from '#/components/design-system/appbar/Appbar';
import { IconButton } from '#/components/design-system/icon-button/IconButton';
import { AccountCircleRounded, Settings as SettingsIcon } from '@mui/icons-material';
import { Menu, MenuItem, Stack, styled, Toolbar } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { CertifyOsLogo } from '../Icons';
import { HelpFormModal } from '../../components/Dashboard/SalesForceHelpModal';
import { useSelector } from 'react-redux';
import { AppState } from '#/redux/reducers/rootReducer';
import { ORGANIZATION_ADMIN_ROLE, allOrgsValue } from '#/Utils/Constants';
import { Notifications } from './Notifications';
const Container = styled(Appbar)(({
  theme
}) => {
  return {
    // @ts-ignore
    borderBottom: `1px solid ${theme.palette.divider}`,
    zIndex: theme.zIndex.drawer + 1
  };
});
const AccountIcon = styled(AccountCircleRounded)(({
  theme
}) => ({
  // @ts-ignore
  fill: theme.palette.primary[400]
}));
const LogoLink = styled(Link)({
  display: 'flex'
});
export const Topbar: React.FC<{
  isLoggedIn?: boolean;
}> = ({
  isLoggedIn
}) => {
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const loggedInUser = useSelector((state: AppState) => state.loggedInUser);
  const isOrgAdmin = useMemo(() => !!loggedInUser.data?.roles?.includes(ORGANIZATION_ADMIN_ROLE), [loggedInUser.data?.roles]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let navItems = <Stack direction="row" alignItems="center" sx={{
    ml: 'auto'
  }}>
      <HelpFormModal modalOpen={!!router.query.showHelpModal} />

      {isOrgAdmin && <a href={'/organization-settings'}>
          {' '}
          <IconButton aria-label="settings-button" size="large">
            <SettingsIcon />
          </IconButton>
        </a>}
      {loggedInUser?.selectedOrgId && loggedInUser.selectedOrgId !== allOrgsValue && <Notifications />}
      <IconButton id="basic-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
        <AccountIcon fontSize="large" />
      </IconButton>
      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{
      'aria-labelledby': 'basic-button'
    }}>
        <MenuItem>
          <a href={'/settings'}> User Settings</a>
        </MenuItem>
        <MenuItem>
          <a href={'/api/users/logout'}> Log out</a>
        </MenuItem>
      </Menu>
    </Stack>;
  if (router.pathname === '/business-requests') {
    navItems = <Stack direction="row" alignItems="center" sx={{
      ml: 'auto'
    }}>
        <IconButton id="basic-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
          <AccountIcon fontSize="large" />
        </IconButton>
        <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}>
          <MenuItem onClick={() => router.push('/settings')}>
            User Settings
          </MenuItem>
          <MenuItem>
            <a href={'/api/users/logout'}> Log out</a>
          </MenuItem>
        </Menu>
      </Stack>;
  }
  return <Container role="toolbar" position="fixed" color="inherit" elevation={0} data-sentry-element="Container" data-sentry-component="Topbar" data-sentry-source-file="index.tsx">
      <Toolbar data-sentry-element="Toolbar" data-sentry-source-file="index.tsx">
        <LogoLink href="/" prefetch={false} title="Home" data-sentry-element="LogoLink" data-sentry-source-file="index.tsx">
          <CertifyOsLogo data-sentry-element="CertifyOsLogo" data-sentry-source-file="index.tsx" />
        </LogoLink>
        {isLoggedIn && navItems}
      </Toolbar>
    </Container>;
};
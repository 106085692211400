import { apiResponseDateToDate } from '#/Utils/date';
import {
  FetchFlagsResponse,
  TransformedFetchFlagsResponse,
} from '#/types/flag.types';
import { get } from 'lodash';

export const transformFetchFlagsResponse = (
  response: FetchFlagsResponse,
): TransformedFetchFlagsResponse => {
  return {
    ...response,
    data: response.data.map((item) => {
      return {
        ...item,
        createdAt: apiResponseDateToDate(item.createdAt),
        reportDate: apiResponseDateToDate(get(item, 'metaData.reportDate')),
        source: get(item, 'metaData.source'),
      };
    }),
  };
};

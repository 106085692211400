import {
  FETCH_SIGNUP_REQUESTS_FAILURE,
  FETCH_SIGNUP_REQUESTS_LOADING,
  FETCH_SIGNUP_REQUESTS_SUCCESS,
  UPDATE_SIGNUP_REQUEST_SUCCESS,
} from '../actionTypes';

export interface SignupRequestFormatted {
  name: string;
  email: string;
  firstName: string;
  lastName: string;
  userId: string;
  createdAt: number;
  description: string;
  id: string;
  status: string;
}

export interface SignupRequestsState {
  data: SignupRequestFormatted[];
  loading: boolean;
  errorMessage?: string;
  successMessage?: string;
}

const initialState: SignupRequestsState = {
  data: [],
  loading: false,
  errorMessage: undefined,
  successMessage: undefined,
};

export const signupRequestsReducer = (
  state: SignupRequestsState = initialState,
  action: { type: string; payload: any },
) => {
  switch (action.type) {
    case FETCH_SIGNUP_REQUESTS_LOADING:
      return {
        ...initialState,
        loading: true,
      };
    case FETCH_SIGNUP_REQUESTS_SUCCESS:
      return {
        data: action.payload.data,
        loading: false,
        successMessage: action.payload.successMessage,
      };
    case FETCH_SIGNUP_REQUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.error.message,
      };
    case UPDATE_SIGNUP_REQUEST_SUCCESS:
      return {
        ...state,
        ...{
          data: state.data.map((item) => {
            if (item.id === action.payload.id) {
              item.status = action.payload.status;
            }
            return item;
          }),
        },
        loading: false,
        errorMessage: action.payload.error.message,
      };
    default:
      return state;
  }
};

import { useSelectedOrganization } from '#/common/hook/useSelectedOrganization';
import { useFeatureFlags } from '#/hooks/useFeatureFlags';
import { LoggedInUserState } from '#/redux/reducers/loggedInUserReducer';
import { AppState } from '#/redux/reducers/rootReducer';
import { PRODUCT_SUPPORT_REQUEST_REASONS } from '#/Utils/Constants';
import { HelpOutline } from '@mui/icons-material';
import * as yup from 'yup';
import { IconButton, Modal, Card, Typography, TextField, Select, MenuItem, Button, InputLabel, FormControl, FormHelperText, CircularProgress } from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useSelector } from 'react-redux';
type HelpFormModalProps = {
  showTrigger?: boolean;
  modalOpen?: boolean;
  isHelpModalOpen?: boolean;
  setIsHelpModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};
const helpDeskFormSchema = yup.object().shape({
  name: yup.string().required('Required'),
  email: yup.string().email().required('Required'),
  reason: yup.string().required('Required'),
  subject: yup.string().required('Required'),
  description: yup.string().required('Required')
});
export const HelpFormModal = (props: HelpFormModalProps) => {
  const {
    showTrigger = true,
    isHelpModalOpen,
    setIsHelpModalOpen
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(props.modalOpen || false);
  const {
    enqueueSnackbar
  } = useSnackbar();
  const selectedOrg = useSelectedOrganization();
  const {
    product_support_to_jira_migration
  } = useFeatureFlags();
  const userData: LoggedInUserState = useSelector((state: AppState) => state.loggedInUser);
  const helpDeskFormInitialValues = {
    name: `${userData.data?.firstName || ''} ${userData.data?.lastName || ''}`,
    email: userData.data?.email || '',
    reason: '',
    subject: '',
    description: ''
  };
  const isJiraEnabled = product_support_to_jira_migration;
  const formSubmissionUrl = isJiraEnabled ? '/api/help-desk' : '/api/salesForce';
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsHelpModalOpen?.(false);
  };
  const onSubmit = async (values: typeof helpDeskFormInitialValues) => {
    try {
      const formData = new URLSearchParams();
      formData.append('organization', selectedOrg?.name || '');
      formData.append('name', values.name);
      formData.append('email', values.email);
      formData.append('subject', values.subject);
      formData.append('description', values.description);
      formData.append('reason', values.reason);
      const response = await fetch(formSubmissionUrl, {
        method: 'POST',
        body: formData
      });
      if (response.ok) {
        enqueueSnackbar('Form submitted successfully!', {
          variant: 'success'
        });
      } else {
        console.error('Form submission failed:', response.statusText);
        enqueueSnackbar('Form submission failed!', {
          variant: 'error'
        });
      }
    } catch (error) {
      console.error('Form submission error');
      enqueueSnackbar('Form submission failed!', {
        variant: 'error'
      });
    }
    setIsModalOpen(false);
  };
  return <>
      {/* Help button */}
      {showTrigger && <IconButton size="large" onClick={handleOpenModal}>
          <HelpOutline />
        </IconButton>}
      <Modal open={isModalOpen || !!isHelpModalOpen} onClose={handleCloseModal} data-sentry-element="Modal" data-sentry-source-file="SalesForceHelpModal.tsx">
        <Card sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4
      }} data-sentry-element="Card" data-sentry-source-file="SalesForceHelpModal.tsx">
          <Typography variant="h5" sx={{
          mb: 2
        }} data-sentry-element="Typography" data-sentry-source-file="SalesForceHelpModal.tsx">
            Need Help?
          </Typography>
          <Formik initialValues={helpDeskFormInitialValues} onSubmit={onSubmit} validationSchema={helpDeskFormSchema} validateOnBlur={false} data-sentry-element="Formik" data-sentry-source-file="SalesForceHelpModal.tsx">
            {({
            values,
            errors,
            handleChange,
            touched,
            handleSubmit,
            isSubmitting
          }) => {
            return <form id="helpForm" onSubmit={handleSubmit}>
                  <TextField name="name" label="Name" variant="outlined" fullWidth margin="normal" value={values.name} onChange={handleChange} error={touched.name && !!errors.name} helperText={touched.name && errors.name} disabled={Boolean(values.name)} />
                  <TextField name="email" label="Email" variant="outlined" fullWidth margin="normal" value={values.email} onChange={handleChange} error={touched.email && !!errors.email} helperText={touched.email && errors.email} disabled={Boolean(values.email)} />
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel id="reason-label">Request Type</InputLabel>
                    <Select name="reason" label="Request Type" variant="outlined" fullWidth value={values.reason} onChange={handleChange} error={touched.reason && !!errors.reason}>
                      {PRODUCT_SUPPORT_REQUEST_REASONS.map(reason => <MenuItem key={reason.label} value={reason.label}>
                          {reason.label}
                        </MenuItem>)}
                    </Select>
                    <FormHelperText error={touched.reason && !!errors.reason}>
                      {touched.reason && errors.reason}
                    </FormHelperText>
                  </FormControl>
                  <TextField name="subject" label="Subject" variant="outlined" fullWidth margin="normal" value={values.subject} onChange={handleChange} error={touched.subject && !!errors.subject} helperText={touched.subject && errors.subject} />
                  <TextField name="description" label="Description" variant="outlined" fullWidth multiline rows={4} margin="normal" value={values.description} onChange={handleChange} error={touched.description && !!errors.description} helperText={touched.description && errors.description} />
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} startIcon={isSubmitting && <CircularProgress color="inherit" size="16px" />}>
                    Submit
                  </Button>
                </form>;
          }}
          </Formik>
        </Card>
      </Modal>
    </>;
};
import { Role } from '#/types';
import { applicationApi } from './application-api.service';
import { TAGS_MAP } from './tags/tags';

export const rolesApi = applicationApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchRoles: builder.query<Role[], void>({
      query: () => ({
        url: '/roles',
        method: 'get',
      }),
      providesTags: [TAGS_MAP.ROLES],
    }),
  }),
});

export const { useFetchRolesQuery } = rolesApi;

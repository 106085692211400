import { createMigrate, persistStore, persistReducer } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import { createLogger } from 'redux-logger';
import migrations from '../Utils/migrations';
import rootReducer from './reducers/rootReducer';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { applicationApi } from '../services/application-api.service';
import { addOrganizationListener } from '../services/organization.listener';
import { clientApi, exportApi } from '../services';

const createNoopStorage = () => {
  return {
    getItem(_key: string) {
      return Promise.resolve(null);
    },
    setItem(_key: string, value: unknown) {
      return Promise.resolve(value);
    },
    removeItem(_key: string) {
      return Promise.resolve();
    },
  };
};

const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage();

const persistConfig = {
  key: 'root',
  storage,
  version: 0, // Change versions of state
  debug: true,
  whitelist: ['auth', 'helpBanners', 'loggedInUser', 'tableColumnVisibility'],
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: true }), // Migrates state based on version number
};
let middlewares: any = [];
if (!['production', 'test'].includes(process.env.NODE_ENV)) {
  const logger = createLogger({
    collapsed: true,
  });
  // middlewares.push(logger);
}

//@ts-ignore
export const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(
      ...middlewares,
      applicationApi.middleware,
      clientApi.middleware,
      exportApi.middleware,
    ),
});
export type AppStore = typeof store;
const makeStore = () => store;
setupListeners(store.dispatch);
addOrganizationListener(store);
export const persistor = persistStore(store);
export const wrapper = createWrapper(makeStore);

import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../Utils/baseQuery';
import { BASE_URL } from '../Utils/Constants';
import { TAGS } from './tags/tags';
export const applicationApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: Object.values(TAGS),
  reducerPath: 'applicationApi',
  endpoints: () => ({}),
});

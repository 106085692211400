import { useCallback, useEffect, useMemo, useState } from 'react';
import nookies from 'nookies';

export const useCookies = (ctx?: any) => {
  const [cookies, setCookies] = useState<Record<string, string>>();

  const refreshCookies = useCallback(() => {
    setCookies(nookies.get(ctx));
  }, [ctx]);

  const getCookie = useCallback(
    (key: string) => {
      const cks = nookies.get(ctx);
      setCookies(cks);
      return cks[key];
    },
    [ctx],
  );

  useEffect(() => {
    //add focus listeners
    if (typeof window !== 'undefined') {
      refreshCookies();
      const cb = refreshCookies;
      window.addEventListener('focus', refreshCookies);
      return () => window.removeEventListener('focus', cb);
    }
  }, [refreshCookies]);
  return useMemo(
    () => ({ cookies, refreshCookies, getCookie }),
    [cookies, refreshCookies, getCookie],
  );
};

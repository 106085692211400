import { ApiResponseDateTime } from '#/Utils/types';
import {
  FETCH_SUPPORTING_DOCUMENTS_LOADING,
  FETCH_SUPPORTING_DOCUMENTS_SUCCESS,
  FETCH_SUPPORTING_DOCUMENTS_FAILURE,
} from '../actionTypes';

export type OrganizationSupportingDocument = {
  id: string;
  createdAt: ApiResponseDateTime;
  createdBy: any;
  credentialingWorkflow: any;
  fileType: string;
  fileUrl: string;
  organizationId: string;
  originalFilename: string;
  providerId: string;
  provider: any;
  signedFileUrl: string;
  uploadSource: string;
};

export interface OrganizationSupportingDocumentsState {
  data: {
    data: OrganizationSupportingDocument[];
    count: number;
  };
  loading: boolean;
  errorMessage?: string;
}

const initialState: OrganizationSupportingDocumentsState = {
  data: {
    data: [],
    count: 0,
  },
  loading: false,
  errorMessage: undefined,
};

export const organizationSupportingDocumentsReducer = (
  state: OrganizationSupportingDocumentsState = initialState,
  action: { type: string; payload: any },
): OrganizationSupportingDocumentsState => {
  switch (action.type) {
    case FETCH_SUPPORTING_DOCUMENTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SUPPORTING_DOCUMENTS_SUCCESS:
      return {
        ...state,
        data: {
          data: action.payload?.data,
          count: action.payload?.count,
        },
        loading: false,
      };
    case FETCH_SUPPORTING_DOCUMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.error.message,
      };
    default:
      return state;
  }
};

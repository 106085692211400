import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
export const CredCommitteeIcon: React.FC<SvgIconProps> = props => {
  return <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="SvgIcon" data-sentry-component="CredCommitteeIcon" data-sentry-source-file="cred-committee.tsx">
      <path opacity="0.3" d="M18.2659 10.6533C18.9709 10.6533 19.5436 11.2675 19.5436 12.0237C19.5436 12.7799 18.971 13.397 18.2659 13.397C17.5609 13.397 16.9882 12.7799 16.9882 12.0237C16.9882 11.2676 17.5608 10.6533 18.2659 10.6533Z" data-sentry-element="path" data-sentry-source-file="cred-committee.tsx" />
      <path opacity="0.3" d="M20.6107 20.2805C20.6188 20.3472 20.5945 20.3935 20.5702 20.4225C20.5485 20.4515 20.508 20.4833 20.4459 20.4833H16.1752C16.1103 20.4833 16.0699 20.4515 16.0482 20.4225C16.0239 20.3935 15.9996 20.3471 16.0077 20.2805L16.4453 16.801C16.4561 16.7112 16.529 16.6445 16.6128 16.6445H20.0055C20.0892 16.6445 20.1622 16.7112 20.173 16.801L20.6107 20.2805Z" data-sentry-element="path" data-sentry-source-file="cred-committee.tsx" />
      <path d="M21.9857 20.0805C22.0452 20.5585 21.9101 21.0394 21.6156 21.4016C21.3212 21.7638 20.8944 21.9724 20.446 21.9724H16.1753C15.7242 21.9724 15.2974 21.7638 15.003 21.4016C14.7085 21.0394 14.5735 20.5585 14.6329 20.0805L15.0705 16.601C15.1758 15.7781 15.8376 15.1553 16.6129 15.1553H20.0056C20.7809 15.1553 21.4427 15.7781 21.5481 16.601L21.9857 20.0805ZM20.5703 20.4224C20.5946 20.3934 20.6189 20.347 20.6108 20.2804L20.1732 16.8009C20.1624 16.7111 20.0895 16.6444 20.0057 16.6444H16.613C16.5293 16.6444 16.4563 16.7111 16.4455 16.8009L16.0079 20.2804C15.9998 20.3471 16.0241 20.3934 16.0484 20.4224C16.07 20.4514 16.1106 20.4832 16.1754 20.4832H20.446C20.5081 20.4832 20.5486 20.4514 20.5703 20.4224Z" data-sentry-element="path" data-sentry-source-file="cred-committee.tsx" />
      <path d="M18.2664 9.16406C19.7359 9.16406 20.9325 10.4475 20.9325 12.0236C20.9325 13.5997 19.7359 14.8832 18.2664 14.8832C16.7969 14.8832 15.6002 13.5997 15.6002 12.0236C15.6002 10.4475 16.7969 9.16406 18.2664 9.16406ZM19.5441 12.0236C19.5441 11.2675 18.9715 10.6532 18.2664 10.6532C17.5614 10.6532 16.9887 11.2674 16.9887 12.0236C16.9887 12.7798 17.5613 13.3969 18.2664 13.3969C18.9714 13.3969 19.5441 12.7798 19.5441 12.0236Z" data-sentry-element="path" data-sentry-source-file="cred-committee.tsx" />
      <path opacity="0.3" d="M10.4841 1.69043C11.1891 1.69043 11.7646 2.30751 11.7646 3.06373C11.7646 3.81995 11.1892 4.43413 10.4841 4.43413C9.77915 4.43413 9.20642 3.81995 9.20642 3.06373C9.20642 2.30751 9.77915 1.69043 10.4841 1.69043Z" data-sentry-element="path" data-sentry-source-file="cred-committee.tsx" />
      <path opacity="0.3" d="M12.8315 11.3177C12.8396 11.3843 12.8125 11.4335 12.7909 11.4625C12.7666 11.4886 12.7261 11.5234 12.664 11.5234H8.39332C8.33116 11.5234 8.29071 11.4886 8.26638 11.4625C8.24476 11.4335 8.21773 11.3842 8.22584 11.3177L8.66613 7.84101C8.67694 7.75124 8.74713 7.68457 8.83091 7.68457H12.2264C12.3101 7.68457 12.3804 7.75124 12.3912 7.84101L12.8315 11.3177Z" data-sentry-element="path" data-sentry-source-file="cred-committee.tsx" />
      <path d="M14.2068 11.1205C14.2663 11.5957 14.1312 12.0795 13.834 12.4416C13.5396 12.8038 13.1128 13.0095 12.6644 13.0095H8.39378C7.94538 13.0095 7.5186 12.8038 7.22417 12.4416C6.92704 12.0795 6.79199 11.5957 6.85136 11.1205L7.29165 7.64099C7.39427 6.81529 8.05881 6.19531 8.83137 6.19531H12.2268C12.9994 6.19531 13.6639 6.81529 13.7666 7.64099L14.2068 11.1205ZM12.7914 11.4624C12.813 11.4334 12.84 11.3841 12.8319 11.3176L12.3916 7.84092C12.3808 7.75115 12.3106 7.68447 12.2268 7.68447H8.83137C8.74767 7.68447 8.67739 7.75115 8.66658 7.84092L8.22629 11.3176C8.21818 11.3842 8.24521 11.4334 8.26683 11.4624C8.29116 11.4885 8.3317 11.5233 8.39378 11.5233H12.6644C12.7266 11.5233 12.7671 11.4885 12.7914 11.4624Z" data-sentry-element="path" data-sentry-source-file="cred-committee.tsx" />
      <path d="M10.4846 0.204102C11.9568 0.204102 13.1508 1.48754 13.1508 3.06366C13.1508 4.63979 11.9569 5.92322 10.4846 5.92322C9.01511 5.92322 7.81848 4.63979 7.81848 3.06366C7.81848 1.48754 9.0152 0.204102 10.4846 0.204102ZM11.7651 3.06366C11.7651 2.30753 11.1897 1.69036 10.4846 1.69036C9.77965 1.69036 9.20692 2.30744 9.20692 3.06366C9.20692 3.81988 9.77956 4.43406 10.4846 4.43406C11.1897 4.43406 11.7651 3.81979 11.7651 3.06366Z" data-sentry-element="path" data-sentry-source-file="cred-committee.tsx" />
      <path opacity="0.3" d="M3.64736 10.6533C4.35235 10.6533 4.92508 11.2675 4.92508 12.0237C4.92508 12.7799 4.35244 13.397 3.64736 13.397C2.94229 13.397 2.36694 12.7799 2.36694 12.0237C2.36694 11.2676 2.94229 10.6533 3.64736 10.6533Z" data-sentry-element="path" data-sentry-source-file="cred-committee.tsx" />
      <path d="M7.36659 20.0805C7.42605 20.5585 7.29091 21.0394 6.99649 21.4016C6.69935 21.7638 6.27258 21.9724 5.82418 21.9724H1.55344C1.10504 21.9724 0.678261 21.7638 0.383832 21.4016C0.089404 21.0394 -0.0456478 20.5585 0.0137262 20.0805L0.451315 16.601C0.556636 15.7781 1.21847 15.1553 1.99373 15.1553H5.3865C6.16176 15.1553 6.82359 15.7781 6.92621 16.601L7.36659 20.0805ZM5.95112 20.4224C5.97545 20.3934 5.99977 20.347 5.99166 20.2804L5.55407 16.8009C5.54056 16.7111 5.47037 16.6444 5.38659 16.6444H1.99382C1.91012 16.6444 1.83714 16.7111 1.82633 16.8009L1.38874 20.2804C1.38063 20.3471 1.40496 20.3934 1.42928 20.4224C1.45091 20.4514 1.49145 20.4832 1.55353 20.4832H5.82418C5.88896 20.4832 5.9295 20.4514 5.95112 20.4224Z" data-sentry-element="path" data-sentry-source-file="cred-committee.tsx" />
      <path opacity="0.3" d="M5.99156 20.2805C5.99967 20.3472 5.97534 20.3935 5.95102 20.4225C5.9294 20.4515 5.88885 20.4833 5.82407 20.4833H1.55334C1.49117 20.4833 1.45072 20.4515 1.4291 20.4225C1.40477 20.3935 1.38045 20.3471 1.38855 20.2805L1.82614 16.801C1.83695 16.7112 1.90984 16.6445 1.99363 16.6445H5.3864C5.4701 16.6445 5.54037 16.7112 5.55388 16.801L5.99156 20.2805Z" data-sentry-element="path" data-sentry-source-file="cred-committee.tsx" />
      <path d="M3.64724 9.16406C5.11677 9.16406 6.3134 10.4475 6.3134 12.0236C6.3134 13.5997 5.11677 14.8832 3.64724 14.8832C2.1751 14.8832 0.981079 13.5997 0.981079 12.0236C0.981079 10.4475 2.17501 9.16406 3.64724 9.16406ZM4.92496 12.0236C4.92496 11.2675 4.35231 10.6532 3.64724 10.6532C2.94216 10.6532 2.36682 11.2674 2.36682 12.0236C2.36682 12.7798 2.94216 13.3969 3.64724 13.3969C4.35231 13.3969 4.92496 12.7798 4.92496 12.0236Z" data-sentry-element="path" data-sentry-source-file="cred-committee.tsx" />
    </SvgIcon>;
};
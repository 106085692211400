import { useEffect, useState } from 'react';

export const useIsWindowFocused = () => {
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    //add focus listeners
    if (typeof window !== 'undefined') {
      const onFocus = () => setIsFocused(true);
      const onBlur = () => setIsFocused(false);

      window.addEventListener('focus', onFocus);
      window.addEventListener('blur', onBlur);

      return () => {
        window.removeEventListener('focus', onFocus);
        window.removeEventListener('blur', onBlur);
      };
    }
  }, []);
  return isFocused;
};

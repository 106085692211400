export {};

export const SANCTION_SOURCE = [
  'Medicare Opt Out',
  'All State Sanctions lists',
  'Office of Inspector General',
  'GSA/SAM/OFAC',
  'Office of Foreign Assets Control',
  'CMS Preclusion List',
  'AK Department of Health Medicaid Program Integrity',
  'AL Medicaid Agency',
  'AR Department of Human Services Excluded Provider List',
  'AZ Health Care Cost Containment System Provider Terminations  & Active Suspensions List',
  'CA Medi-Cal List',
  'CO Department of Health Care Policy & Financing Terminated Provider List',
  'CT Quality Assurance Administrative Actions List',
  'DC Excluded Parties List',
  'DE Sanctioned Provider Report',
  'FL Agency for Health Care Administration Public Record Search',
  'GA OIG Exclusions List',
  'HI Provider Exclusion/Reinstatement List',
  'IA Iowa Department of Health and Human Services Excluded Individuals and Entities',
  'ID Department of Health and Wellness',
  'IN Family and Social Services Administration',
  'KS Department of Health and Environment',
  'KY ​​​​​Department for Medicaid Services',
  'LA Department of Health',
  'MA MassHealth List of suspended and excluded providers',
  'MD Medicaid Sanctioned Provider List',
  'ME Department of Health and Human Services Provider Exclusion Report',
  'MI Health & Human Services List of suspended and excluded providers',
  'MN Department of Human Services Excluded Provider List',
  'MO Department of Social Services Provider Sanctions',
  'MS Division of Medicaid Provider Terminations',
  'MT Terminated or Excluded Providers',
  'NC Medicaid Division of Health Benefits Excluded Providers',
  'ND Medicaid Provider Exclusion List',
  'NE Department of Health and Human Services Exclusion List',
  'NH Department of Health and Human Services Medicaid Provider Exclusion and Sanction List',
  'NJ Consolidated Debarment Report',
  'NV Medicaid Provider Exclusions and Sanctions',
  'NY Office of the Medicaid Inspector General Medicaid Exclusions',
  'OH Medicaid Provider Exclusion and Suspension List',
  'OR Health Authority State Medicaid Fraud Convictions',
  'PA Medicheck List of Precluded Providers',
  'SC Excluded Providers',
  'TN Office of Program Integrity Terminated Provider List',
  'TX Office of Inspector General Exclusion Database File',
  'VT Medicaid Excluded Provider List',
  'WA Health Care Authority Provider termination and Exclusion list',
  'WV Medicaid Provider Exclusions and Terminations',
  'WY Medicaid Exclusion List',
];

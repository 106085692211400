import { Job } from '#/types';
import { applicationApi } from './application-api.service';

export const jobsApi = applicationApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchJob: builder.query<Job, string>({
      query: (id) => ({
        url: `/jobs/${id}`,
        method: 'get',
      }),
    }),
  }),
});

export const { useLazyFetchJobQuery } = jobsApi;

import { formatDateFilterPayload, formatToFilterPayload } from '#/api/utils';
import {
  transformMonitoringWorkflow,
  transformMonitoringWorkflows,
} from '#/data-transformers/monitoring.transformer';
import { pickBy } from 'lodash';
import type { CreateMonitoringWorkflowPayload } from '../api/monitoring';
import { MonitoringApiParams } from '../redux/actions/monitoring';
import {
  MonitoringWorkflow,
  MonitoringWorkflowListState,
} from '../redux/reducers/monitoringWorkflowsReducer';
import { applicationApi } from './application-api.service';
import { TAGS_MAP } from './tags/tags';
import { tagsWithId } from './tags/utils';
import { AnalystData } from '#/types';
import { ProviderFormData } from '#/components/ProviderList/ProviderListFilterForm';

const generateQuery = (
  workflowId: string,
  method: string,
  url: string,
  data?: any,
) => ({
  url: `/monitoring-workflows/${workflowId}${url}`,
  method,
  data,
});

const { MONITORING_WORKFLOW: TAG_MONITORING_WORKFLOW } = TAGS_MAP;

export const monitoringApiV2 = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    fetchMonitoringWorkflow: builder.query<MonitoringWorkflow, string>({
      query: (workflowId) => generateQuery(workflowId, 'GET', ''),
      transformResponse: transformMonitoringWorkflow,
      providesTags: (result) => tagsWithId(TAG_MONITORING_WORKFLOW, result),
    }),
    createMonitoringWorkflow: builder.mutation<
      { id: string },
      CreateMonitoringWorkflowPayload
    >({
      query: ({ providerId }) => ({
        url: '/monitoring-workflows',
        method: 'post',
        data: { providerId },
        responseType: 'json',
      }),
      invalidatesTags: (result) =>
        result
          ? [
              { type: TAG_MONITORING_WORKFLOW, id: result.id },
              TAG_MONITORING_WORKFLOW,
            ]
          : [TAG_MONITORING_WORKFLOW],
    }),
    updateMonitoringWorkflow: builder.mutation<
      any,
      { workflowId: string; data: any }
    >({
      query: ({ workflowId, data }) =>
        generateQuery(workflowId, 'PATCH', '', data),
      invalidatesTags: (result) => tagsWithId(TAG_MONITORING_WORKFLOW, result),
    }),
    triggerMonitoringProvidersCount: builder.mutation<void, void>({
      query: () => ({
        url: '/monitoring-workflows/trigger-monitoring-count-for-org',
        method: 'post',
      }),
      invalidatesTags: [TAGS_MAP.PROVIDERS_FOR_MONITORING],
    }),
    removeSectionFlag: builder.mutation<
      any,
      { workflowId: string; sectionName: string }
    >({
      query: ({ workflowId, sectionName }) =>
        generateQuery(workflowId, 'PATCH', '/remove-section-flag', {
          sectionName,
        }),
      invalidatesTags: (result, error, arg) =>
        tagsWithId(TAG_MONITORING_WORKFLOW, { id: arg.workflowId }),
    }),
    updateMonitoringStatus: builder.mutation<
      void,
      { workflowId: string; apiPath: string }
    >({
      query: ({ workflowId, apiPath }) =>
        generateQuery(workflowId, 'PUT', `/steps/${apiPath}`),
      invalidatesTags: (result, error, arg) =>
        tagsWithId(TAG_MONITORING_WORKFLOW, { id: arg.workflowId }),
    }),
    monitoringSaveNote: builder.mutation<
      void,
      { workflowId: string; message: string }
    >({
      query: ({ workflowId, message }) =>
        generateQuery(workflowId, 'POST', `/notes`, { message }),
      invalidatesTags: (result, error, arg) =>
        tagsWithId(TAG_MONITORING_WORKFLOW, { id: arg.workflowId }),
    }),
    fetchMonitoringWorkflows: builder.query<
      MonitoringWorkflowListState,
      MonitoringApiParams
    >({
      query: (params) => {
        let url = `/monitoring-workflows`;
        let formattedParams;
        if (params) {
          formattedParams = formatDateFilterPayload(params);
          formatToFilterPayload(formattedParams);
          delete formattedParams.includeTerminatedProviders;

          formattedParams.flagsSources =
            !!formattedParams.flagsSources &&
            !Array.isArray(formattedParams.flagsSources)
              ? [formattedParams.flagsSources]
              : formattedParams.flagsSources;
        }

        return {
          url,
          method: 'GET',
          params: pickBy(formattedParams),
        };
      },
      transformResponse: transformMonitoringWorkflows,
      providesTags: (_, __, params) => [
        { type: TAGS_MAP.MONITORING_WORKFLOW_LIST },
        { type: TAGS_MAP.MONITORING_WORKFLOW_LIST, ...params },
      ],
    }),
    assignWorkflowsMonitoring: builder.mutation<
      void,
      {
        workflowIds: string[];
        userIds: string[];
        filters: ProviderFormData;
      }
    >({
      query: (args) => {
        if (args.filters) {
          args.filters = formatDateFilterPayload(args.filters);
          formatToFilterPayload(args.filters);
        }
        return {
          url: '/monitoring-workflows/assign-to-analysts',
          method: 'PATCH',
          data: args,
        };
      },
      invalidatesTags: () => [
        TAGS_MAP.MONITORING_ANALYST,
        TAGS_MAP.MONITORING_WORKFLOW_LIST,
      ],
    }),
    fetchAnalystsMonitoring: builder.query<AnalystData[], void>({
      query: () => ({
        url: '/monitoring-workflows/analysts',
        method: 'GET',
      }),
      providesTags: [TAGS_MAP.MONITORING_ANALYST],
    }),
  }),
});

export const {
  useCreateMonitoringWorkflowMutation,
  useFetchMonitoringWorkflowQuery,
  useRemoveSectionFlagMutation,
  useUpdateMonitoringStatusMutation,
  useMonitoringSaveNoteMutation,
  useFetchAnalystsMonitoringQuery,
  useAssignWorkflowsMonitoringMutation,
  useFetchMonitoringWorkflowsQuery,
  useTriggerMonitoringProvidersCountMutation,
  useLazyFetchMonitoringWorkflowsQuery,
} = monitoringApiV2;

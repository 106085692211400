import { Grid, Typography } from '@mui/material';
import { Circle as CircleIcon } from '@mui/icons-material';
import { ENotificationType, ExportMetadata } from '#/types';
interface NotificationProps {
  title?: string;
  subtitle?: string;
  description?: string;
  read?: boolean;
  onClick: () => void;
}
export function getTitle(title: string, type?: string, metadata?: ExportMetadata) {
  let formattedTitle = title;
  switch (type) {
    // adding providerName to title for psv notifications
    case ENotificationType.PSV_GENERATION:
      if (title && metadata?.providerFirstName && metadata?.providerLastName) {
        formattedTitle = `${title} - ${metadata.providerFirstName} ${metadata.providerLastName}`;
      }
      break;
  }
  return formattedTitle;
}
export const NotificationCard: React.FC<NotificationProps> = ({
  title,
  subtitle,
  description,
  read,
  onClick
}) => {
  return <Grid container sx={{
    maxWidth: '240px',
    '&:hover': {
      cursor: 'pointer'
    }
  }} onClick={onClick} data-sentry-element="Grid" data-sentry-component="NotificationCard" data-sentry-source-file="index.tsx">
      <Grid item xs={1} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        {!read && <CircleIcon sx={{
        fontSize: '12px',
        marginTop: theme => theme.spacing(0.6)
      }} color="secondary" />}
      </Grid>
      <Grid item xs={11} sx={{
      paddingLeft: theme => theme.spacing(1.25)
    }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Typography variant="body1" sx={(theme: any) => ({
        fontWeight: !read ? 'bold' : 'normal',
        color: theme.text?.surface?.medium
      })} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
          {title}
        </Typography>
        <Typography variant="overline" data-sentry-element="Typography" data-sentry-source-file="index.tsx">{subtitle}</Typography>
        <Typography variant="body2" sx={(theme: any) => ({
        color: !read ? theme.text?.surface?.medium : theme.text?.surface?.disabled
      })} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
          {description}
        </Typography>
      </Grid>
    </Grid>;
};
import React from 'react';
export const ErrorBoundaryIcon = () => {
  return <svg width="356" height="402" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="ErrorBoundaryIcon" data-sentry-source-file="ErrorBoundaryIcon.tsx">
      <g clip-path="url(#a)" data-sentry-element="g" data-sentry-source-file="ErrorBoundaryIcon.tsx">
        <path d="M138.4 138.8H59.1V179h79.3v-40.2ZM296.9 138.8h-79.3V179h79.3v-40.2ZM178 98.6H98.7v40.2H178V98.6ZM257.3 98.6H178v40.2h79.3V98.6ZM217.6 259.4h79.3v-40.2h-79.3v40.2ZM59.1 259.4h79.3v-40.2H59.1v40.2ZM178 299.6h79.3v-40.2H178v40.2Z" fill="#fff" stroke="#040610" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="M257.2 299.6h79.3v-40.2h-79.3v40.2ZM19.5 299.6h79.3v-40.2H19.5v40.2ZM257.2 138.8h79.3V98.6h-79.3v40.2ZM19.5 138.8h79.3V98.6H19.5v40.2Z" fill="#fff" stroke="#040610" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="M98.7 299.6H178v-40.2H98.7v40.2ZM336.5 179h-79.3v40.2h79.3V179ZM98.8 179H19.5v40.2h79.3V179Z" fill="#fff" stroke="#040610" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="M178 225.8c15.5 0 28-13.4 28-29.9S193.6 166 178 166s-28 13.4-28 29.9 12.5 29.9 28 29.9Z" fill="#79709D" stroke="#040610" stroke-width="1.5" stroke-miterlimit="10" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="m178 183-13 12.9 13 13" fill="#fff" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="m178 183-13 12.9 13 13" stroke="#040610" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="m178 183 13 12.9-13 13" fill="#fff" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="m178 183 13 12.9-13 13" stroke="#040610" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="M216.3 77.3 272 43l6.2 31.6-55.8 34.4-6.2-31.7Z" fill="#fff" stroke="#040610" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="m188.7 71 55.9-34.4L272 43l-55.8 34.3-27.6-6.4Z" fill="#FFF38B" stroke="#040610" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="m188.7 71 6.2 31.6 27.6 6.4-6.2-31.7-27.6-6.4Z" fill="#E5974D" stroke="#040610" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="m248.7 342.8-45.5-47.2 29.7-12.8 45.4 47.3-29.6 12.7Z" fill="#FFF38B" stroke="#040610" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="m248.2 371-45.4-47.2.4-28.2 45.5 47.2-.5 28.2Z" fill="#E5974D" stroke="#040610" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="m248.2 371 29.7-12.7.4-28.2-29.6 12.7-.5 28.2Z" fill="#fff" stroke="#040610" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="M88.7 118.6 29 91.5l22.8-22.8 59.8 27-22.9 22.9Z" fill="#FFF38B" stroke="#040610" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="m98.8 145-59.7-27.1-10-26.4 59.6 27L98.8 145Z" fill="#E5974D" stroke="#040610" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="m98.8 145 22.9-22.9-10.1-26.4-22.9 22.9 10.1 26.3ZM84.4 369l62.2-20.7-1.2 32.2-62.1 20.8 1.1-32.2Z" fill="#fff" stroke="#040610" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="m59 356.6 62.3-20.8 25.3 12.5L84.4 369l-25.3-12.5Z" fill="#FFF38B" stroke="#040610" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="M59 356.6 58 388.8l25.4 12.5 1.1-32.2-25.3-12.5Z" fill="#E5974D" stroke="#040610" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="m197.5 239.3 120.3 83.4M153 249.3 13.5 349.9M117 33l42.1 112M122.4.5l24.2 68.2M225.7 159l120-105.1M1 159l134.1 28M87.3 322.6l51-43M230.4 199.1H355M218.8 204l57.8 8.7M171.4 317.6l-6 43.3" stroke="#040610" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        <path d="M303.7 110.3c0 7.1-6.8 7.7-6.8 7.7 6 0 6.8 7.8 6.8 7.8s.8-7.8 6.8-7.8c0 0-6.8-.6-6.8-7.7ZM310.5 348.8c0 7.2-6.8 7.7-6.8 7.7 6 0 6.8 7.9 6.8 7.9s.8-7.9 6.8-7.9c0 0-6.8-.5-6.8-7.7ZM71.2 322.3c0 7.1-6.8 7.7-6.8 7.7 6 0 6.8 7.9 6.8 7.9S72 330 78 330c0 0-6.8-.6-6.8-7.7ZM203.2 147.7c0 3.7-3.5 4-3.5 4 3.1 0 3.5 4.1 3.5 4.1s.5-4 3.6-4c0 0-3.6-.4-3.6-4.1ZM44.5 235.3c0 3.7-3.5 4-3.5 4 3.1 0 3.5 4 3.5 4s.5-4 3.6-4c0 0-3.6-.3-3.6-4ZM162 28c0 7-6.8 7.7-6.8 7.7 6 0 6.8 7.8 6.8 7.8s.8-7.8 6.8-7.8c0 0-6.8-.6-6.8-7.8ZM171.3 40c0 3.7-3.5 4-3.5 4 3 0 3.5 4 3.5 4s.4-4 3.5-4c0 0-3.5-.3-3.5-4Z" fill="#fff" stroke="#040610" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="ErrorBoundaryIcon.tsx">
        <clipPath id="a" data-sentry-element="clipPath" data-sentry-source-file="ErrorBoundaryIcon.tsx">
          <path fill="#fff" transform="translate(.5)" d="M0 0h355v402H0z" data-sentry-element="path" data-sentry-source-file="ErrorBoundaryIcon.tsx" />
        </clipPath>
      </defs>
    </svg>;
};
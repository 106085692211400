import { FetchOrgSecretsApiResponseItem } from '../../api/organization';
import {
  CREATE_SECRET_FAILURE,
  CREATE_SECRET_LOADING,
  CREATE_SECRET_SUCCESS,
  DELETE_SECRETS_FAILURE,
  DELETE_SECRETS_LOADING,
  DELETE_SECRETS_SUCCESS,
  FETCH_SECRETS_FAILURE,
  FETCH_SECRETS_LOADING,
  FETCH_SECRETS_SUCCESS,
} from '../actionTypes';

export interface OrgSecretsState {
  data: FetchOrgSecretsApiResponseItem[];
  loading: boolean;
  creating: boolean;
  deleting: boolean;
}

const initialState = {
  data: [],
  loading: false,
  creating: false,
  deleting: false,
  errorMessage: undefined,
};

export const orgSecretsReducer = (
  state: OrgSecretsState = initialState,
  action: { type: string; payload: any },
) => {
  switch (action.type) {
    case CREATE_SECRET_LOADING:
      return {
        ...state,
        creating: true,
      };
    case CREATE_SECRET_SUCCESS:
      return {
        ...state,
        creating: false,
      };
    case CREATE_SECRET_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload.error.message,
      };
    case DELETE_SECRETS_LOADING:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_SECRETS_SUCCESS:
      return {
        ...state,
        deleting: false,
      };
    case DELETE_SECRETS_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload.error.message,
      };
    case FETCH_SECRETS_LOADING:
      return {
        ...initialState,
        loading: true,
      };
    case FETCH_SECRETS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case FETCH_SECRETS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.error.message,
      };
    default:
      return state;
  }
};

import { ENotificationType, Notification } from '#/types';
import { Box, Button } from '@mui/material';
import { useRouter } from 'next/router';
export const NotificationActions: React.FC<NotificationActionsProps> = ({
  notification,
  onRead
}: NotificationActionsProps) => {
  const getActions = () => {
    switch (notification.type) {
      case ENotificationType.LICENSING_REQUEST_CREATED:
      case ENotificationType.LICENSING_WORKFLOW_ASSIGNED:
        return <LicensingNotificationActions notification={notification} onRead={onRead} />;
      default:
        return null;
    }
  };
  if (!NOTIFICATION_TYPES_WITH_ACTION_BTNS.includes(notification.type)) {
    return null;
  }
  return getActions();
};
export interface NotificationActionsProps {
  notification: Notification;
  onRead: () => void;
}
const NOTIFICATION_TYPES_WITH_ACTION_BTNS = [ENotificationType.LICENSING_REQUEST_CREATED, ENotificationType.LICENSING_WORKFLOW_ASSIGNED];
const LicensingNotificationActions: React.FC<{
  notification: Notification;
  onRead: () => void;
}> = ({
  notification,
  onRead
}) => {
  const router = useRouter();
  const licensingWorkflowId = notification?.metadata?.workflowId;
  const viewDetails = () => {
    onRead?.();
    if (!licensingWorkflowId) {
      router.push(`/licensing`);
      return;
    }
    router.push(`/licensing/${licensingWorkflowId}`);
  };
  return <Box sx={{
    display: 'flex',
    paddingTop: theme => theme.spacing(0.5),
    justifyContent: 'right'
  }} data-sentry-element="Box" data-sentry-component="LicensingNotificationActions" data-sentry-source-file="NotificationActions.tsx">
      {notification.type === ENotificationType.LICENSING_REQUEST_CREATED && <Button size="small" color="primary" onClick={viewDetails} variant="contained">
          View
        </Button>}
      {notification.type === ENotificationType.LICENSING_WORKFLOW_ASSIGNED && <Button size="small" color="primary" onClick={viewDetails} variant="contained">
          Start Work
        </Button>}
    </Box>;
};
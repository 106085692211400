import {
  PEWorkflowOutreachCorrespondences,
  PEWorkflowOutreachOverview,
  PEWorkflowOutreachResponse,
} from '#/types/provider-enrollment-workflows.types';
import { get, sortBy } from 'lodash';

export const transformFetchAllWorkflowOutreachAttemptsResponse = (
  response: PEWorkflowOutreachResponse,
): PEWorkflowOutreachResponse => {
  const rootMessages = response.correspondences.filter(
    (msg) => !msg.rootMessageId,
  );
  const correspondences: PEWorkflowOutreachCorrespondences[] = rootMessages.map(
    (rootMsg) => {
      if (rootMsg.method !== 'email') return rootMsg;
      const replies = response.correspondences.filter(
        (msg) =>
          msg.rootMessageId &&
          rootMsg.sgMessageId &&
          msg.rootMessageId === rootMsg.sgMessageId,
      );
      return {
        ...rootMsg,
        replies: sortBy(
          replies,
          (reply) => -1 * get(reply, 'createdAt._seconds', 0),
        ),
      };
    },
  );
  const overviewItems = response.overview || [];
  const overview = overviewItems.reduce(
    (acc: PEWorkflowOutreachOverview[], item) => {
      if (item.rootMessageId) return acc;
      if (!!item.sgMessageId) {
        item.replyCount = overviewItems.filter(
          (item2) => item2.rootMessageId === item.sgMessageId,
        ).length;
      }
      acc.push(item);
      return acc;
    },
    [],
  );
  return {
    correspondences,
    overview,
  };
};

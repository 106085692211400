import { FetchFacilitiesListApiResponse } from '#/api/facilities';
import { CollapsedDataGridProps } from '#/components/CollapseTable';
import { OrgEntity } from '#/types';
import {
  FacilityErrorResponseFromServer,
  FacilityErrorResponseItem,
  FacilityDetails,
  FacilityNppesData,
  FetchFacilityByIdResponse,
  FetchFacilityListResponse,
} from '#/types/facility.types';
import { facilityDataCards, PREFERRED_TIMEZONE } from '#/Utils/Constants';
import { HsdSpecialty, Specialties } from '../types';
import {
  apiResponseDateToDate,
  convertToLocaleDate,
  firestoreDateInUtc,
} from '#/Utils/date';
import { groupBy } from 'lodash';
import { CREDENTIALING_TRANSFORMER_SOURCES } from './credentialing.transformer';

const processSupportingDocsData = (
  data: FetchFacilityByIdResponse,
  cardConfig: any,
) => {
  return {
    // @ts-ignore
    data: (data[cardConfig.id] || []).map((card) => {
      return {
        id: card.id,
        fileType: card.type,
        originalFilename: card.name,
        createdAt: firestoreDateInUtc(card.lastModified),
        fileUrl: card.fileUrl,
      };
    }),
    config: cardConfig,
  };
};

export const processDataCard =
  (
    data: FetchFacilityByIdResponse,
    source?: CREDENTIALING_TRANSFORMER_SOURCES,
  ) =>
  (cardConfig: any) => {
    let cardData: any[] = [];
    if (cardConfig.id === 'facility-specialties') {
      return {
        data: {
          facilityHsdSpecialty: data.facilityHsdSpecialty,
          facilityPrimarySpecialty: data.facilityPrimarySpecialty,
        },
        config: cardConfig,
      };
    }
    if (cardConfig.id === 'facilitySupportingDocuments') {
      return processSupportingDocsData(data, cardConfig);
    }
    if (cardConfig.cardType === 'form') {
      cardData = Object.keys(cardConfig.fields).map((field: string) => {
        // @ts-ignore
        let value;
        if (
          cardConfig.id === 'credentialingTimeline' &&
          data.credentialingWorkflowTimeline
        ) {
          value = data.credentialingWorkflowTimeline[field];
        } else if (
          cardConfig.id === 'overview' &&
          field === 'facilityPrimarySpecialty'
        ) {
          value = `${data.facilityPrimarySpecialty?.name} (${data.facilityPrimarySpecialty?.specialtyNuccTaxonomyCode})`;
        } else if (
          cardConfig.id === 'overview' &&
          field === 'facilityHsdSpecialty'
        ) {
          value = `${data.facilityHsdSpecialty?.name} (${data.facilityHsdSpecialty?.code})`;
        } else {
          value = data[field as keyof FetchFacilityByIdResponse];
        }
        const fieldConfig = cardConfig.fields[field];

        if (fieldConfig.type === 'date') {
          const isFacilityCredialingTimeline =
            source ===
              CREDENTIALING_TRANSFORMER_SOURCES.FACILITY_CREDENTIALING_TRANSFORMER &&
            cardConfig.id === 'credentialingTimeline';
          value = isFacilityCredialingTimeline
            ? convertToLocaleDate(value, PREFERRED_TIMEZONE)
            : firestoreDateInUtc(value);
        }

        return {
          id: cardConfig.id,
          key: field,
          // @ts-ignore
          ...cardConfig.fields[field],
          value,
        };
      });
      // @ts-ignore
    } else if (cardConfig.cardType === 'table' && data[cardConfig.id]) {
      // @ts-ignore
      cardData = data[cardConfig.id].map((row) => {
        return Object.keys(cardConfig.fields).reduce(
          (acc, field) => {
            const fieldConfig = cardConfig.fields[field];
            try {
              if (fieldConfig.type === 'date' && acc[field]) {
                if (acc[field]._seconds) {
                  acc[field] = firestoreDateInUtc(acc[field]);
                } else {
                  acc[field] = new Date(acc[field]);
                }
              }
            } catch (err) {
              console.log('Invalid date ', { field, cardId: cardConfig.id });
            }
            return acc;
          },
          { ...row },
        );
      });
    } else {
      // @ts-ignore
      cardData = data[cardConfig.id];
    }
    return {
      data: cardData,
      config: cardConfig,
    };
  };

export const transformFetchFacilityByIdResponse = (
  data: FetchFacilityByIdResponse,
  source?: CREDENTIALING_TRANSFORMER_SOURCES,
): FacilityDetails => {
  const processedData: FacilityDetails = {
    id: data.id,
    name: data.name,
    dba: data.dba,
    type: data.type,
    status: data.credentialingStatus,
    fileTypeScores: data.fileTypeScores,
    sections: facilityDataCards.map(processDataCard(data, source)),
    facilityId: data.facilityId,
    facilitySpecialties: data['facility-specialties'],
    facilityHsdSpecialty: data.facilityHsdSpecialty,
    facilityPrimarySpecialty: data.facilityPrimarySpecialty,
    nppes: transformNppesData(data.nppes?.[0]),
    npi: data.npi,
  };
  return processedData;
};

const transformNppesData = (nppes?: FacilityNppesData) => {
  if (!nppes) {
    return;
  }

  return {
    ...nppes,
    verifiedAt: apiResponseDateToDate(nppes.verifiedAt),
    createdAt: apiResponseDateToDate(nppes.createdAt),
    updatedAt: apiResponseDateToDate(nppes.updatedAt),
    certificationDate: apiResponseDateToDate(nppes.certificationDate),
  } as FacilityNppesData;
};

export const transformFacilitiesResponse = (
  response: FetchFacilitiesListApiResponse,
): FetchFacilityListResponse => {
  const { data, count } = response;
  const records: FacilityDetails[] = data.map((facilityResponseItem) => {
    const listItem: FacilityDetails = {
      ...facilityResponseItem,
      status: '',
      sections: [],
      facilityId: facilityResponseItem.facilityId,
    };
    return listItem;
  });
  return { data: records, count };
};

export const transformFacilityOrgEntities = (
  orgEntities: OrgEntity[],
): CollapsedDataGridProps | undefined => {
  return orgEntities?.length
    ? {
        label: `${orgEntities
          .slice(0, 2)
          .map(({ generalInfo }) => generalInfo?.name)
          .join(
            ', ',
          )}${orgEntities.length > 2 ? ` and ${orgEntities.length - 2} more` : ''}`,
        tableData: {
          columns: [{ field: 'name', headerName: 'Group Name', width: 200 }],
          rows: orgEntities.map(({ generalInfo, id }) => ({
            name: generalInfo?.name,
            id,
          })),
        },
      }
    : undefined;
};

const generateErrorMessage = (response: FacilityErrorResponseFromServer) => {
  let result: FacilityErrorResponseItem[] = [];
  return Object.entries(groupBy(response, 'reason')).reduce(
    (acc, [key, val]) => {
      const err = {
        reason: key,
        facilityIds: val.map((v) => v.facilityId),
        facilityNames: val.map((p) => p.facilityName),
      };
      acc.push(err);
      return acc;
    },
    result,
  );
};

export const transformTerminationResponse = (
  response: FacilityErrorResponseFromServer,
): FacilityErrorResponseItem[] | string => {
  if (Array.isArray(response)) {
    return generateErrorMessage(response);
  }

  return `Facilities terminated successfully`;
};

export const transformUnterminationResponse = (
  response: FacilityErrorResponseFromServer,
): FacilityErrorResponseItem[] | string => {
  if (Array.isArray(response)) {
    return generateErrorMessage(response);
  }

  return `Facilities unterminated successfully`;
};

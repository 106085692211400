import { CredentialingWorkflowOnStep } from '../../api/credentialing';
import { FlagObject } from '../../api/monitoring';
import { Flag, ProviderStatus } from '../../types';
import { Assignment, WorkflowNote } from '../../types/credentialing.types';
import {
  FETCH_MONITORING_WORKFLOWS_FAILURE,
  FETCH_MONITORING_WORKFLOWS_SUCCESS,
  FETCH_MONITORING_WORKFLOWS_LOADING,
  REVIEW_MONITORING_WORKFLOWS_LOADING,
  REVIEW_MONITORING_WORKFLOWS_SUCCESS,
  REVIEW_MONITORING_WORKFLOWS_FAILURE,
  SAVE_MONITORING_WORKFLOW_NOTE_LOADING,
  SAVE_MONITORING_WORKFLOW_NOTE_SUCCESS,
  SAVE_MONITORING_WORKFLOW_NOTE_FAILURE,
} from '../actionTypes';

export interface MonitoringWorkflow {
  [x: string]: any;
  createdBy: string;
  updatedBy: string;
  createdAt?: Date | null;
  providerFileType: string;
  providerLastName: string;
  assignedStates: string[];
  providerStates: string;
  providerStatus: ProviderStatus;
  providerFirstName: string;
  isCompleted: false;
  version: number;
  organizationId: string;
  missingData: string[];
  providerType: string;
  updatedAt?: Date | null;
  name: string;
  providerNpi: string;
  providerId: string;
  id: string;
  flags: string[];
  currentFlags: Record<string, Flag>;
  flagsObjects: FlagObject[];
  onStep: CredentialingWorkflowOnStep;
  reviewCompletedBy?: string;
  reviewCompleteDate?: Date | null;
  outreachReasons: string[];
  notes?: WorkflowNote[];
  assignment?: Assignment;
}

export interface MonitoringWorkflowListState {
  data: MonitoringWorkflow[];
  count?: number;
  loading?: boolean;
  updating?: boolean;
  reviewing?: boolean;
  reviewProviderIds?: string[];
  errorMessage?: string;
}

const initialState: MonitoringWorkflowListState = {
  data: [],
  count: 0,
  loading: false,
  reviewing: false,
  updating: false,
  reviewProviderIds: [],
  errorMessage: undefined,
};

export const monitoringWorkflowsReducer = (
  state: MonitoringWorkflowListState = initialState,
  action: { type: string; payload: any },
) => {
  switch (action.type) {
    case SAVE_MONITORING_WORKFLOW_NOTE_LOADING:
      return {
        ...state,
        updating: true,
      };
    case SAVE_MONITORING_WORKFLOW_NOTE_SUCCESS:
      return {
        ...state,
        updating: false,
      };
    case SAVE_MONITORING_WORKFLOW_NOTE_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload?.error?.message,
      };
    case REVIEW_MONITORING_WORKFLOWS_LOADING:
      return {
        ...state,
        reviewing: true,
        reviewProviderIds: [],
      };
    case REVIEW_MONITORING_WORKFLOWS_SUCCESS:
      return {
        ...state,
        reviewing: false,
        reviewProviderIds: action.payload,
      };
    case REVIEW_MONITORING_WORKFLOWS_FAILURE:
      return {
        ...state,
        reviewing: false,
        reviewProviderIds: [],
      };
    case FETCH_MONITORING_WORKFLOWS_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case FETCH_MONITORING_WORKFLOWS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload?.error?.message,
      };
    case FETCH_MONITORING_WORKFLOWS_SUCCESS:
      return {
        ...state,
        data: action.payload?.data,
        count: action.payload?.count,
        loading: false,
      };
    default:
      return state;
  }
};

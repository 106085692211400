import { BASE_URL } from '../Utils/Constants';
import axios from 'axios';

export const login = async (data: any) => {
  const url = `${BASE_URL}/auth-tokens`;
  const response = await axios.post(url, data, {
    disableSnackbar: true,
  });

  return response.data;
};

export const checkToken = async () => {
  const url = `${BASE_URL}/auth-tokens`;
  const response = await axios.get(url, {
    disableSnackbar: true,
  });
  return response.data;
};

export const logout = async () => {
  const url = `${BASE_URL}/auth-tokens`;
  const response = await axios.delete(url);

  return response.data;
};
export const resetPassword = async (
  id: string,
  currentPassword: string,
  newPassword: string,
) => {
  const url = `${BASE_URL}/users/${id}/password`;
  const response = await axios.put(url, {
    currentPassword,
    newPassword,
  });
  return response.data;
};

import { createTheme } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';

declare module '@mui/material/styles/createPalette' {
  export interface PaletteOptions {
    border: {
      light: string;
    };
    yellow: {
      default: string;
    };
    rowItem: {
      default: string;
      error: string;
      warning: string;
    };
  }
}
declare module '@mui/material/styles' {
  interface Theme {
    text: {
      primary: {
        high: string;
        medium: string;
        disabled: string;
      };
      surface: {
        high: string;
        medium: string;
        disabled: string;
      };
    };
    shape: {
      borderRadius: number;
      borderRadius2x: number;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    text?: {
      primary?: {
        high?: string;
        medium?: string;
        disabled?: string;
      };
      surface?: {
        high?: string;
        medium?: string;
        disabled?: string;
      };
    };
    shape?: {
      borderRadius?: number;
      borderRadius2x?: number;
    };
  }
}

export const appTheme = createTheme({
  palette: {
    primary: {
      light: '#FFF38B',
      main: '#F3C948',
      dark: '#E5974D',
      contrastText: 'rgba(4, 6, 16, 1)',
    },
    secondary: {
      main: '#FFFFFF',
      dark: '#040610',
      contrastText: 'rgba(4, 6, 16, 1)',
    },
    info: {
      main: '#C9BFE9',
      dark: '#79709E',
      light: '#D9D5F7',
      contrastText: 'rgba(4, 6, 16, 1)',
    },
    error: {
      main: '#B00020',
      light: '#F44336',
    },
    success: {
      main: '#4CAF50',
      light: '#aedcae',
    },
    background: {
      default: '#F6F6F6',
      paper: '#fff',
    },
    border: {
      light: '#F6F6F6',
    },
    yellow: {
      default: '#FFF9C4',
    },
    rowItem: {
      default: '#FAFAFA',
      error: '#FEEBEE',
      warning: '#FFF3E0',
    },
  },
  typography: {
    fontFamily: 'ABCDiatype, sans-serif',
  },
  text: {
    primary: {
      high: 'rgba(4, 6, 16, 1)',
      medium: 'rgba(4, 6, 16, 0.74)',
      disabled: 'rgba(4, 6, 16, 0.38)',
    },
    surface: {
      high: 'rgba(0, 0, 0, 1)',
      medium: 'rgba(0, 0, 0, .60)',
      disabled: 'rgba(0, 0, 0, .38)',
    },
  },
  shape: {
    borderRadius: 8,
    borderRadius2x: 16,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h5: {
          fontSize: '24px',
        },
        h4: {
          fontSize: '30px',
        },
        h3: {
          fontSize: '36px',
        },
        h2: {
          fontSize: '48px',
        },
        h1: {
          fontSize: '64px',
        },
        body1: {
          fontSize: '16px',
        },
        body2: {
          fontSize: '14px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          borderColor: 'rgba(0, 0, 0, 0.38)',
          color: 'rgba(0, 0, 0, 0.87)',
        },
        colorSuccess: {
          backgroundColor: '#E8F5E9',
        },
        colorWarning: {
          backgroundColor: '#FFFDE7',
        },
        colorError: {
          backgroundColor: '#FEEBEE',
          color: 'rgba(0, 0, 0, 1)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => {
          return theme.unstable_sx({
            '&.MuiButton-textPrimary .MuiSvgIcon-root': {
              color: '#E5974D !important',
            },
            '&.Mui-disabled .MuiSvgIcon-root': {
              color: 'rgba(0, 0, 0, 0.15) !important',
            },
          });
        },
        outlinedPrimary: {
          color: '#E5974D',
        },
        textPrimary: {
          color: '#E5974D',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#E5974D',
          textDecoration: 'underline',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => {
          return theme.unstable_sx({
            '.Mui-focused': {
              color: '#000 !important',
            },
          });
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => {
          return theme.unstable_sx({
            '.MuiTabs-indicator': {
              display: 'none',
            },
            '.Mui-selected': {
              background: '#F3C948',
              borderRadius: '8px',
              color: '#040610 !important',
              fontWeight: 400,
            },
          });
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: ({ theme }) => {
          return theme.unstable_sx({
            '&.MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
              backgroundColor: theme.palette.info.main,
            },
          });
        },
      },
    },
  },
});

import nookies from 'nookies';

import { AppStore } from '../redux/store';
import { AppState } from '../redux/reducers/rootReducer';
import { applicationApi } from './application-api.service';
import { ORGANIZATION_DEPENDENT_TAGS } from './tags/tags';

const selectOrganizationId = (state: AppState): string | undefined => {
  return state?.loggedInUser?.selectedOrgId;
};

let currentOrganizationId: string | undefined = undefined;

export const addOrganizationListener = (store: AppStore) => {
  store.subscribe(() => {
    let prevOrgId = currentOrganizationId;
    currentOrganizationId = selectOrganizationId(
      store.getState() as unknown as AppState,
    );

    if (prevOrgId !== currentOrganizationId) {
      prevOrgId &&
        store.dispatch(
          applicationApi.util.invalidateTags(ORGANIZATION_DEPENDENT_TAGS),
        );

      if (currentOrganizationId) {
        nookies.set(undefined, 'organization_id', currentOrganizationId, {
          secure: true,
          sameSite: 'strict',
          path: '/',
          maxAge: Date.now() * 3,
        });
      } else {
        nookies.destroy(undefined, 'organization_id');
      }

      // this is need to prevent infinite loading.
      if (!prevOrgId) {
        return;
      }

      // reloading page to mitigate caching issues with lazy loaded data and this also prevents broken link issues due to organisation switch.
      setTimeout(() => {
        location.href = location.origin;
      });
    }
  });
};

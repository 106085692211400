import {
  AssignMembersPayload,
  CreateOrgApiPayload,
  FetchOrgListApiResponse,
  FetchOrgResponse,
  FetchOrgsQueryParams,
  OrgList,
} from '#/types/organization.types';
import { apiResponseDateToDate } from '#/Utils/date';
import { applicationApi } from './application-api.service';
import { TAGS_MAP } from './tags/tags';
import { tagsWithId, tagsWithIdList } from './tags/utils';

export const organizationsApi = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    fetchOrgs: builder.query<OrgList, FetchOrgsQueryParams | void>({
      query: (queryParams: FetchOrgsQueryParams) => ({
        url: '/organizations',
        method: 'get',
        data: null,
        params: queryParams!,
      }),
      transformResponse(response: FetchOrgListApiResponse) {
        return {
          orgs: response.orgs.map((item) => {
            return {
              ...item,
              createdAt: apiResponseDateToDate(item.createdAt),
              lastMonitoringDate: apiResponseDateToDate(
                item.lastMonitoringDate,
              ),
            };
          }),
          count: response.count,
        };
      },
      providesTags: (result) =>
        tagsWithIdList(TAGS_MAP.ORGANIZATIONS, result?.orgs),
    }),
    createOrg: builder.mutation<{ id: string }, CreateOrgApiPayload>({
      query: (payload) => ({
        url: `/organizations`,
        method: 'post',
        data: payload,
      }),
      invalidatesTags: (result) => tagsWithId(TAGS_MAP.ORGANIZATIONS, result),
    }),
    fetchOrg: builder.query<FetchOrgResponse, string>({
      query: (orgId) => ({
        url: `/organizations/${orgId}`,
        method: 'get',
      }),
      providesTags: (result, error, orgId) =>
        tagsWithId(TAGS_MAP.ORGANIZATIONS, { id: orgId }),
    }),
    assignMembers: builder.mutation<unknown, AssignMembersPayload>({
      query: (payload: AssignMembersPayload) => ({
        url: `/organizations/memberships/${payload.organizationId}/assign-members`,
        method: 'post',
        data: { userIds: payload.userIds },
      }),
    }),
  }),
});

export const {
  useFetchOrgsQuery,
  useCreateOrgMutation,
  useAssignMembersMutation,
  useLazyFetchOrgQuery,
  useFetchOrgQuery,
} = organizationsApi;

import {
  UPDATE_ACTIVE_CREDENTIALING_WORKFLOW_STEP_FAILURE,
  UPDATE_ACTIVE_CREDENTIALING_WORKFLOW_STEP_LOADING,
  UPDATE_ACTIVE_CREDENTIALING_WORKFLOW_STEP_SUCCESS,
  UPDATE_CREDENTIALING_WORKFLOW_FAILURE,
  UPDATE_CREDENTIALING_WORKFLOW_LOADING,
  UPDATE_CREDENTIALING_WORKFLOW_SUCCESS,
  UPLOAD_OUTREACH_DOCUMENTS_FAILURE,
  UPLOAD_OUTREACH_DOCUMENTS_LOADING,
  UPLOAD_OUTREACH_DOCUMENTS_SUCCESS,
} from '../actionTypes';

export interface CredentialingWorkflowStep {
  id: string;
  createdAt: Date;
  description: string;
  title?: string;
  name: string;
  status: string;
  seqNo: number;
  error: boolean;
  enteredAt?: Date;
  exitedAt?: Date;
}

export interface CredentialingWorkflowNote {
  id: string;
  message: string;
  createdByName: string;
  createdAt: Date | null;
  createdBy: string;
}

export interface CredentialingWorkflowStepsState {
  data: {
    workflowId?: string;
    providerId?: string;
    organizationId?: string;
    version?: number;
    isCompleted?: boolean;
    psvFile?: string;
    psvFileSignedUrl?: string;
    outreachReasons?: string[];
    onStep?: CredentialingWorkflowStep;
    steps: CredentialingWorkflowStep[];
    credCommDetails: any;
    notes: CredentialingWorkflowNote[];
    credentialingCycle?: string;
  };
  loading?: boolean;
  updating?: boolean;
  savingNote?: boolean;
  updatingNote?: boolean;
  deletingNote?: boolean;
  sendingOutreachEmail?: boolean;
  uploadingOutreachDocuments?: boolean;
  errorMessage?: string;
}

const initialState: CredentialingWorkflowStepsState = {
  data: {
    workflowId: undefined,
    providerId: undefined,
    organizationId: undefined,
    version: undefined,
    steps: [],
    notes: [],
    credCommDetails: {},
  },
  loading: false,
  updating: false,
  sendingOutreachEmail: false,
  errorMessage: undefined,
};

export const credentialingWorkflowStepsReducer = (
  state: CredentialingWorkflowStepsState = initialState,
  action: { type: string; payload: any },
) => {
  switch (action.type) {
    case UPDATE_CREDENTIALING_WORKFLOW_SUCCESS:
    case UPDATE_CREDENTIALING_WORKFLOW_FAILURE:
    case UPDATE_ACTIVE_CREDENTIALING_WORKFLOW_STEP_SUCCESS:
    case UPDATE_ACTIVE_CREDENTIALING_WORKFLOW_STEP_FAILURE:
      return {
        ...state,
        updating: false,
      };
    case UPDATE_CREDENTIALING_WORKFLOW_LOADING:
    case UPDATE_ACTIVE_CREDENTIALING_WORKFLOW_STEP_LOADING:
      return {
        ...state,
        updating: true,
      };
    case UPLOAD_OUTREACH_DOCUMENTS_LOADING:
      return {
        ...state,
        uploadingOutreachDocuments: true,
      };
    case UPLOAD_OUTREACH_DOCUMENTS_SUCCESS:
    case UPLOAD_OUTREACH_DOCUMENTS_FAILURE:
      return {
        ...state,
        uploadingOutreachDocuments: false,
      };
    default:
      return state;
  }
};

import { useFlags } from 'flagsmith/react';
import { FlagOptions } from '../types';
import {
  FEATURE_FLAG_KEYS,
  isFeatureFlagKey,
} from '../constants/feature-flags/feature-flags.constants';
import { useCookies } from './useCookies';
import { useEffect, useMemo, useState } from 'react';
export const useFeatureFlags = () => {
  const remoteFeatureFlags = useFlags<FlagOptions>(FEATURE_FLAG_KEYS);

  const { cookies } = useCookies();
  const [featureFlagOverrides, setFeatureFlagOverrides] = useState<
    Partial<Record<FlagOptions, boolean>>
  >({});
  useEffect(() => {
    if (cookies && process.env.ENVIRONMENT !== 'production') {
      const cookieOverrides: Partial<Record<FlagOptions, boolean>> = {};
      Object.entries(cookies).forEach(([key, value]) => {
        const shortKey = key.replace('feature_flag_', '');
        const isFeatureFlagCookie = isFeatureFlagKey(shortKey);
        if (isFeatureFlagCookie && ['enabled', 'disabled'].includes(value)) {
          cookieOverrides[shortKey] = value === 'enabled';
        }
      });
      setFeatureFlagOverrides(cookieOverrides);
    }
  }, [cookies]);

  const remoteFeatureFlagsMap = useMemo<Record<FlagOptions, boolean>>(
    () =>
      Object.fromEntries(
        FEATURE_FLAG_KEYS.map((key) => [
          key,
          !!remoteFeatureFlags[key]?.enabled,
        ]),
      ) as Record<FlagOptions, boolean>,
    [remoteFeatureFlags],
  );
  return useMemo<Record<FlagOptions, boolean>>(
    () => ({
      ...remoteFeatureFlagsMap,
      ...featureFlagOverrides,
    }),
    [featureFlagOverrides, remoteFeatureFlagsMap],
  );
};

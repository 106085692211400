export const downloadFile = (filename: string, data: any) => {
  const linkUrl = window.URL.createObjectURL(new Blob([data]));
  downloadFileFromUrl(linkUrl, filename);
};

export const downloadFileFromUrl = (url: string, filename: string) => {
  if (!url) return;
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../Utils/baseQuery';
export const clientApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: '/api' }),
  tagTypes: ['TAG_AUTH', 'TAG_SESSION'],
  reducerPath: 'clientApi',
  endpoints: (builder) => ({
    getSession: builder.query<
      { organizationId?: string; loggedIn: boolean },
      void
    >({
      query: () => ({
        url: '/users/session',
        method: 'get',
      }),
      providesTags: ['TAG_AUTH', 'TAG_SESSION'],
    }),
  }),
  refetchOnMountOrArgChange: true,
  // refetchOnFocus: true,
});
const { useGetSessionQuery } = clientApi;
export { useGetSessionQuery };

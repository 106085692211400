import {
  CreateCredentialingWorkflowResponse,
  FetchCredentialingWorkflowsAPIResponse,
} from '#/api/credentialing';
import { MyTeamsWorkProviderListFormData } from '#/components/MyTeamsWork/ProvidersTab/FilterForm';
import { ProviderFormData } from '#/components/ProviderList/ProviderListFilterForm';
import { transformTeamsWorkProvidersQueryParams } from '#/data-transformers/teams-work.transformer';

import {
  FetchProviderListApiResponse,
  MyTeamsWorkAnalystData,
  TeamsWorkBulkCancelCredentialingWorkflowsPayload,
  TeamsWorkBulkCreateCredentialingWorkflowPayload,
} from '../types';
import { applicationApi } from './application-api.service';
import { TAGS_MAP } from './tags/tags';

export const teamsWorkApi = applicationApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchTeamsWorkCredentialing: builder.query<
      FetchCredentialingWorkflowsAPIResponse,
      ProviderFormData
    >({
      query: (params) => {
        return {
          url: '/teams-work/credentialing',
          method: 'GET',
          params,
        };
      },
      providesTags: (_, __, params) => [
        { type: TAGS_MAP.TEAMS_WORK_CREDENTIALING_LIST },
        { type: TAGS_MAP.TEAMS_WORK_CREDENTIALING_LIST, ...params },
      ],
    }),
    fetchTeamsWorkProviders: builder.query<
      FetchProviderListApiResponse,
      MyTeamsWorkProviderListFormData
    >({
      query: (params) => {
        return {
          url: '/teams-work/providers',
          method: 'GET',
          params: transformTeamsWorkProvidersQueryParams(params),
        };
      },
      providesTags: (_, __, params) => [
        { type: TAGS_MAP.TEAMS_WORK_PROVIDERS_LIST },
        { type: TAGS_MAP.TEAMS_WORK_PROVIDERS_LIST, ...params },
      ],
    }),
    fetchMyTeamsAnalysts: builder.query<MyTeamsWorkAnalystData[], void>({
      query: () => ({
        url: '/teams-work/analysts',
        method: 'GET',
      }),
      providesTags: [TAGS_MAP.MY_TEAMS_CREDENTIAL_ANALYST],
    }),
    assignMyTeamWorkflows: builder.mutation<
      void,
      {
        workflowIds: string[];
        userIds: string[];
        filters: ProviderFormData;
      }
    >({
      query: (args) => {
        return {
          url: '/teams-work/bulk-assign',
          method: 'POST',
          data: args,
        };
      },
      invalidatesTags: () => [TAGS_MAP.MY_TEAMS_CREDENTIAL_ANALYST],
    }),
    fetchMyWork: builder.query<
      FetchCredentialingWorkflowsAPIResponse,
      ProviderFormData
    >({
      query: (params) => {
        return {
          url: '/teams-work/me/credentialing',
          method: 'GET',
          params,
        };
      },
      providesTags: (_, __, params) => [
        { type: TAGS_MAP.MY_WORK_LIST },
        { type: TAGS_MAP.MY_WORK_LIST, ...params },
      ],
    }),
    bulkCreateCredentialingWorkflow: builder.mutation<
      CreateCredentialingWorkflowResponse,
      TeamsWorkBulkCreateCredentialingWorkflowPayload
    >({
      query: (args) => {
        return {
          url: '/teams-work/bulk-create-credentialing-workflow',
          method: 'POST',
          data: args,
        };
      },
      invalidatesTags: () => [TAGS_MAP.TEAMS_WORK_PROVIDERS_LIST],
    }),
    bulkCancelCredentialingWorkflow: builder.mutation<
      void,
      TeamsWorkBulkCancelCredentialingWorkflowsPayload
    >({
      query: (args) => {
        return {
          url: '/teams-work/credentialing/bulk-cancel',
          method: 'PUT',
          data: args,
        };
      },
      invalidatesTags: () => [TAGS_MAP.TEAMS_WORK_CREDENTIALING_LIST],
    }),
  }),
});

export const {
  useFetchTeamsWorkCredentialingQuery,
  useLazyFetchTeamsWorkCredentialingQuery,
  useFetchTeamsWorkProvidersQuery,
  useLazyFetchTeamsWorkProvidersQuery,
  useFetchMyTeamsAnalystsQuery,
  useAssignMyTeamWorkflowsMutation,
  useFetchMyWorkQuery,
  useLazyFetchMyWorkQuery,
  useBulkCreateCredentialingWorkflowMutation,
  useBulkCancelCredentialingWorkflowMutation,
} = teamsWorkApi;

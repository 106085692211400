import axios from 'axios';
import nookies from 'nookies';
import { store } from '../redux/store';
import SnackbarUtils from './SnackbarUtils';
import { AppState } from '../redux/reducers/rootReducer';
import { allOrgsValue } from './Constants';
import * as Sentry from '@sentry/nextjs';
declare module 'axios' {
  export interface AxiosRequestConfig {
    disableSnackbar?: boolean;
    disableSetToken?: boolean;
  }
}

export const requestErrorInterceptor = (error: any) => {
  /*
  some errors in sentry are reported with status code 0 and empty response object. 
  example: https://certifyos.sentry.io/issues/4594314631/
  */
  const isValidError = error.response && error.response.status > 0;

  // errors inside the range of 400-500 are expected in the application
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (
    error.response?.status === 401 &&
    error.response?.data?.message === 'Unauthorized'
  ) {
    document.location = '/api/users/logout';
    return Promise.reject(error);
  }

  if (!error.config.disableSnackbar) {
    if (!expectedError) {
      SnackbarUtils.error('Something went wrong');
    } else {
      SnackbarUtils.error(error.response.data.message);
    }
  }

  // only log to sentry if Error is valid and un-expected
  if (isValidError && !expectedError) {
    Sentry.captureException(error);
  }

  if (error?.message) {
    return Promise.reject(error);
  } else {
    return Promise.reject(
      Error(`Error without message: ${JSON.stringify(error)}`, {
        cause: error,
      }),
    );
  }
};

export const addAxiosInterceptors = () => {
  const requestId = axios.interceptors.request.use((config) => {
    config.params = config.params || {};
    config.headers = config.headers || {};
    config.withCredentials = true;
    const cookies = nookies.get();
    const { loggedInUser, auth } = store.getState() as unknown as AppState;
    if (cookies.selected_org_id && cookies.selected_org_id !== allOrgsValue) {
      config.headers['organization-id'] = cookies.selected_org_id;
    } else if (
      loggedInUser?.data &&
      loggedInUser?.selectedOrgId &&
      loggedInUser?.selectedOrgId !== allOrgsValue
    ) {
      config.headers['organization-id'] = loggedInUser?.selectedOrgId;
    }
    if (typeof auth.token === 'string' && auth.token !== 'true') {
      config.headers['authorization'] = 'Bearer ' + auth.token;
    }

    return config;
  });

  const responseId = axios.interceptors.response.use((res) => {
    return res;
  }, requestErrorInterceptor);
  return () => {
    axios.interceptors.response.eject(responseId);
    axios.interceptors.request.eject(requestId);
  };
};
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};

import { MyTeamsWorkProviderListFormData } from '#/components/MyTeamsWork/ProvidersTab/FilterForm';

const getVerficationsKeysValue = (
  value?: string,
): boolean | string | undefined => {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    case 'null':
      return 'null';
    default:
      return undefined;
  }
};

export const transformTeamsWorkProvidersQueryParams = (
  formData: MyTeamsWorkProviderListFormData,
) => {
  let params: any = { ...formData };
  if (formData) {
    params.caqhRostered = getVerficationsKeysValue(formData.caqhRostered);
    params.npdbEnrolled = getVerficationsKeysValue(formData.npdbEnrolled);
    params.nppesNameCheck = getVerficationsKeysValue(formData.nppesNameCheck);
  }

  return params;
};

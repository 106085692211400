export const FETCH_LOGGEDIN_USER_LOADING =
  'FETCH_LOGGEDIN_USER_LOADING' as const;
export const FETCH_LOGGEDIN_USER_SUCCESS =
  'FETCH_LOGGEDIN_USER_SUCCESS' as const;
export const FETCH_LOGGEDIN_USER_FAILURE =
  'FETCH_LOGGEDIN_USER_FAILURE' as const;
export const UPDATE_LOGGEDIN_USER_LOADING =
  'UPDATE_LOGGEDIN_USER_LOADING' as const;
export const UPDATE_LOGGEDIN_USER_SUCCESS =
  'UPDATE_LOGGEDIN_USER_SUCCESS' as const;
export const UPDATE_LOGGEDIN_USER_FAILURE =
  'UPDATE_LOGGEDIN_USER_FAILURE' as const;
export const UPDATE_MONITORING_STATUS_LOADING =
  'UPDATE_MONITORING_STATUS_LOADING' as const;
export const UPDATE_MONITORING_STATUS_SUCCESS =
  'UPDATE_MONITORING_STATUS_SUCCESS' as const;
export const UPDATE_MONITORING_STATUS_FAILURE =
  'UPDATE_MONITORING_STATUS_FAILURE' as const;
export const FETCH_SIGNUP_REQUESTS_LOADING =
  'FETCH_SIGNUP_REQUESTS_LOADING' as const;
export const FETCH_SIGNUP_REQUESTS_SUCCESS =
  'FETCH_SIGNUP_REQUESTS_SUCCESS' as const;
export const FETCH_SIGNUP_REQUESTS_FAILURE =
  'FETCH_SIGNUP_REQUESTS_FAILURE' as const;
export const UPDATE_SIGNUP_REQUEST_LOADING =
  'UPDATE_SIGNUP_REQUEST_LOADING' as const;
export const UPDATE_SIGNUP_REQUEST_SUCCESS =
  'UPDATE_SIGNUP_REQUEST_SUCCESS' as const;
export const UPDATE_SIGNUP_REQUEST_FAILURE =
  'UPDATE_SIGNUP_REQUEST_FAILURE' as const;
export const RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING' as const;
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS' as const;
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE' as const;
export const UPDATE_USER_ROLES_LOADING = 'UPDATE_USER_ROLES_LOADING' as const;
export const UPDATE_USER_ROLES_SUCCESS = 'UPDATE_USER_ROLES_SUCCESS' as const;
export const UPDATE_USER_ROLES_FAILURE = 'UPDATE_USER_ROLES_FAILURE' as const;
export const UPDATE_CREDENTIALING_WORKFLOW_LOADING =
  'UPDATE_CREDENTIALING_WORKFLOW_LOADING' as const;
export const UPDATE_CREDENTIALING_WORKFLOW_SUCCESS =
  'UPDATE_CREDENTIALING_WORKFLOW_SUCCESS' as const;
export const UPDATE_CREDENTIALING_WORKFLOW_FAILURE =
  'UPDATE_CREDENTIALING_WORKFLOW_FAILURE' as const;
export const UPDATE_ACTIVE_CREDENTIALING_WORKFLOW_STEP_LOADING =
  'UPDATE_ACTIVE_CREDENTIALING_WORKFLOW_STEP_LOADING' as const;
export const UPDATE_ACTIVE_CREDENTIALING_WORKFLOW_STEP_SUCCESS =
  'UPDATE_ACTIVE_CREDENTIALING_WORKFLOW_STEP_SUCCESS' as const;
export const UPDATE_ACTIVE_CREDENTIALING_WORKFLOW_STEP_FAILURE =
  'UPDATE_ACTIVE_CREDENTIALING_WORKFLOW_STEP_FAILURE' as const;
export const CREATE_SECRET_LOADING = 'CREATE_SECRET_LOADING' as const;
export const CREATE_SECRET_SUCCESS = 'CREATE_SECRET_SUCCESS' as const;
export const CREATE_SECRET_FAILURE = 'CREATE_SECRET_FAILURE' as const;
export const FETCH_SECRETS_LOADING = 'FETCH_SECRETS_LOADING' as const;
export const FETCH_SECRETS_SUCCESS = 'FETCH_SECRETS_SUCCESS' as const;
export const FETCH_SECRETS_FAILURE = 'FETCH_SECRETS_FAILURE' as const;
export const DELETE_SECRETS_LOADING = 'DELETE_SECRETS_LOADING' as const;
export const DELETE_SECRETS_SUCCESS = 'DELETE_SECRETS_SUCCESS' as const;
export const DELETE_SECRETS_FAILURE = 'DELETE_SECRETS_FAILURE' as const;
export const HIDE_HELP_BANNER = 'HIDE_HELP_BANNER' as const;
export const HIDE_ALL_HELP_BANNERS = 'HIDE_ALL_HELP_BANNERS' as const;
export const UPDATE_SELECTED_ORG = 'UPDATE_SELECTED_ORG' as const;
export const BLOCK_USER_LOADING = 'BLOCK_USER_LOADING' as const;
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS' as const;
export const BLOCK_USER_FAILURE = 'BLOCK_USER_FAILURE' as const;
export const UNBLOCK_USER_LOADING = 'UNBLOCK_USER_LOADING' as const;
export const UNBLOCK_USER_SUCCESS = 'UNBLOCK_USER_SUCCESS' as const;
export const UNBLOCK_USER_FAILURE = 'UNBLOCK_USER_FAILURE' as const;
export const UPLOAD_OUTREACH_DOCUMENTS_LOADING =
  'UPLOAD_OUTREACH_DOCUMENTS_LOADING' as const;
export const UPLOAD_OUTREACH_DOCUMENTS_SUCCESS =
  'UPLOAD_OUTREACH_DOCUMENTS_SUCCESS' as const;
export const UPLOAD_OUTREACH_DOCUMENTS_FAILURE =
  'UPLOAD_OUTREACH_DOCUMENTS_FAILURE' as const;
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS' as const;
export const FETCH_MONITORING_WORKFLOWS_LOADING =
  'FETCH_MONITORING_WORKFLOWS_LOADING' as const;
export const FETCH_MONITORING_WORKFLOWS_SUCCESS =
  'FETCH_MONITORING_WORKFLOWS_SUCCESS' as const;
export const FETCH_MONITORING_WORKFLOWS_FAILURE =
  'FETCH_MONITORING_WORKFLOWS_FAILURE' as const;

export const REVIEW_MONITORING_WORKFLOWS_LOADING =
  'REVIEW_MONITORING_WORKFLOWS_LOADING' as const;
export const REVIEW_MONITORING_WORKFLOWS_SUCCESS =
  'REVIEW_MONITORING_WORKFLOWS_SUCCESS' as const;
export const REVIEW_MONITORING_WORKFLOWS_FAILURE =
  'REVIEW_MONITORING_WORKFLOWS_FAILURE' as const;
export const FETCH_SUPPORTING_DOCUMENTS_LOADING =
  'FETCH_SUPPORTING_DOCUMENTS_LOADING' as const;
export const FETCH_SUPPORTING_DOCUMENTS_SUCCESS =
  'FETCH_SUPPORTING_DOCUMENTS_SUCCESS' as const;
export const FETCH_SUPPORTING_DOCUMENTS_FAILURE =
  'FETCH_SUPPORTING_DOCUMENTS_FAILURE' as const;
export const SAVE_MONITORING_WORKFLOW_NOTE_LOADING =
  'SAVE_MONITORING_WORKFLOW_NOTE_LOADING' as const;
export const SAVE_MONITORING_WORKFLOW_NOTE_SUCCESS =
  'SAVE_MONITORING_WORKFLOW_NOTE_SUCCESS' as const;
export const SAVE_MONITORING_WORKFLOW_NOTE_FAILURE =
  'SAVE_MONITORING_WORKFLOW_NOTE_FAILURE' as const;
export const UPDATE_TABLE_COLUMN_VISIBILITY =
  'UPDATE_TABLE_COLUMN_VISIBILITY' as const;

import { PLATFORM_TABLES, PlatformTable } from '#/Utils/Constants';
import { UPDATE_TABLE_COLUMN_VISIBILITY } from '../actionTypes';

export interface TableColumnVisibilityState {
  name: PlatformTable;
  columns: {
    [columnId: string]: boolean;
  };
}

const initialState = [
  {
    name: PLATFORM_TABLES.PAYOR_ENROLLMENT_WORKFLOWS,
    columns: {
      verifiedAt: false,
      completionDate: false,
      submittedAt: false,
      previousActivityDate: false,
    },
  },
];

export const tableColumnVisibilityReducer = (
  state: TableColumnVisibilityState[] = initialState,
  action: { type: string; payload: TableColumnVisibilityState },
) => {
  switch (action.type) {
    case UPDATE_TABLE_COLUMN_VISIBILITY: {
      let tableConfigFound = false;
      let newState = state.map((tableConfig) => {
        if (tableConfig.name === action.payload.name) {
          tableConfigFound = true;
          return action.payload;
        }
        return tableConfig;
      });
      if (!tableConfigFound) {
        newState = [...newState, action.payload];
      }
      return newState;
    }
    default:
      return state;
  }
};

import { useMemo } from 'react';
import { useGetSessionQuery } from '../services';

export type AuthSession = {
  loading: boolean;
  organizationId?: string;
  loggedIn?: boolean;
};
export const useSession = (): AuthSession => {
  const { data: session, isFetching } = useGetSessionQuery();

  return useMemo(
    () => ({
      loading: isFetching,
      organizationId: session?.organizationId,
      loggedIn: session?.loggedIn,
    }),
    [isFetching, session?.organizationId, session?.loggedIn],
  );
};

import { transformFetchFlagsResponse } from '#/data-transformers/flags.transformer';
import {
  FetchFlagsQueryParams,
  FlagsOverviewDto,
  FlagsOverviewResponse,
  TransformedFetchFlagsResponse,
  FlagUpdateManyRequestPayload,
  FlagUpdateManyResponsePayload,
} from '#/types/flag.types';
import { applicationApi } from './application-api.service';
import { TAGS_MAP } from './tags/tags';

export const flagsApi = applicationApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    fetchFlags: builder.query<
      TransformedFetchFlagsResponse,
      FetchFlagsQueryParams
    >({
      query: (params) => {
        return {
          url: '/flags',
          method: 'get',
          params,
        };
      },
      transformResponse: transformFetchFlagsResponse,
      providesTags: [TAGS_MAP.FLAGS],
    }),
    fetchFlagsOverview: builder.query<
      FlagsOverviewResponse[],
      FlagsOverviewDto
    >({
      query: (params) => {
        return {
          url: '/v2/flags/flags-overview/monitoring',
          method: 'get',
          responseType: 'json',
          params,
        };
      },
    }),
    markFlagAsRead: builder.mutation<
      unknown,
      { flagId: string; reason: string }
    >({
      query: ({ flagId, reason }) => {
        return {
          url: `/flags/${flagId}/mark-read`,
          method: 'patch',
          data: { reason },
        };
      },
      invalidatesTags: [TAGS_MAP.FLAGS],
    }),
    markFlagAsUnread: builder.mutation<unknown, string>({
      query: (flagId: string) => {
        return {
          url: `/flags/${flagId}/mark-unread`,
          method: 'patch',
        };
      },
      invalidatesTags: [TAGS_MAP.FLAGS],
    }),
    activateFlag: builder.mutation<unknown, string>({
      query: (flagId: string) => {
        return {
          url: `/flags/${flagId}/activate`,
          method: 'patch',
        };
      },
      invalidatesTags: [TAGS_MAP.FLAGS],
    }),
    deactivateFlag: builder.mutation<unknown, string>({
      query: (flagId: string) => {
        return {
          url: `/flags/${flagId}/deactivate`,
          method: 'patch',
        };
      },
      invalidatesTags: [TAGS_MAP.FLAGS],
    }),
    updateManyFlags: builder.mutation<
      FlagUpdateManyResponsePayload,
      FlagUpdateManyRequestPayload
    >({
      query: (payload) => {
        return {
          url: `/flags`,
          method: 'patch',
          data: payload,
        };
      },
      invalidatesTags: [TAGS_MAP.FLAGS],
    }),
  }),
});

export const {
  useFetchFlagsOverviewQuery,
  useActivateFlagMutation,
  useDeactivateFlagMutation,
  useMarkFlagAsUnreadMutation,
  useMarkFlagAsReadMutation,
  useUpdateManyFlagsMutation,
  useFetchFlagsQuery,
} = flagsApi;

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '#/redux/reducers/rootReducer';
interface OrganizationDetails {
  id: string;
  name: string;
}
export const useSelectedOrganization = (): OrganizationDetails | null => {
  const loggedInUser = useSelector((state: AppState) => state.loggedInUser);
  return useMemo(() => {
    if (!loggedInUser.data || !loggedInUser.selectedOrgId) {
      return null;
    }
    const selectedOrg = loggedInUser.data.memberships?.find(mem => mem.organizationId === loggedInUser.selectedOrgId);
    if (!selectedOrg) {
      console.warn('Selected organization not found in user memberships');
      return null;
    }
    return {
      id: selectedOrg.organizationId,
      name: selectedOrg.organizationName
    };
  }, [loggedInUser.data, loggedInUser.selectedOrgId]);
};